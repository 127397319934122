import React, { FC, useState, useCallback, useEffect } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { BsBagFill as BagIcon } from 'react-icons/bs';
import * as Sentry from '@sentry/nextjs';

import emailIsValid from 'utils/emailIsValid';
import linkIsRelative from 'utils/linkIsRelative';
import * as Gtag from 'clients/Gtag';
import EscapodAPI from 'clients/Escapod';
import Fbq from 'clients/Fbq';
import { useNewsletterContext } from 'contexts/NewsletterContext';
import { useNavContext } from 'contexts/NavContext';
import { useCartState, useCartMutate } from 'contexts/CartContext';
import useScrollDistance from 'hooks/useScrollDistance';

import { Button, TextField, FormWrapper, LinkButton } from 'components';
import { Menu } from 'types';

import iconClose from '../public/images/v2-icon-close.svg';
import iconMenuWhite from '../public/images/v2-icon-menu-white.svg';
import iconMenuBlack from '../public/images/v2-icon-menu.svg';
import logoWhite from '../public/images/v2-logo-white.svg';
import logoBlack from '../public/images/v2-logo.svg';

const CONVERSION_ROUTES = ['/order-now', '/rentals'];

const Nav: FC<{ menu: Menu[] }> = ({ menu }) => {
  const navContext = useNavContext();
  const cartState = useCartState();
  const cartMutate = useCartMutate();
  const scrollDistance = useScrollDistance();

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);
  const [error, setError] = useState<{ [key: string]: string }>({});
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const openMenu = useCallback(() => setMenuIsOpen(true), [setMenuIsOpen]);
  const closeMenu = useCallback(() => setMenuIsOpen(false), [setMenuIsOpen]);
  const [cartIsOpen, setCartIsOpen] = useState<boolean>(false);
  const openCart = useCallback(() => setCartIsOpen(true), [setCartIsOpen]);
  const closeCart = useCallback(() => setCartIsOpen(false), [setCartIsOpen]);
  const newsletterContext = useNewsletterContext();

  const hasScrolledBeyondHero = scrollDistance > 400;
  const computedTheme = menuIsOpen || hasScrolledBeyondHero ? 'black' : navContext.theme;
  const lineItemCount = cartState?.lineItems.reduce(
    (total: number, item) => total + item.quantity,
    0
  );

  const page = useRouter();

  const submit = useCallback(() => {
    setError({});
    setIsSending(true);

    if (!firstName) return setError({ firstName: 'Enter your first name.' });
    if (!firstName) return setError({ lastName: 'Enter your last name.' });
    if (!email || !emailIsValid(email)) return setError({ email: 'Enter a valid email address.' });

    Sentry.setUser({ email });

    EscapodAPI.klaviyo
      .subscribe({ firstName, lastName, email })
      .then(res => {
        if (res.status === 201) {
          Gtag.event('conversion', {
            send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
            transaction_id: window.btoa(email),
            event_callback: () => {}
          });
          setError({ form: 'Thank you for your submission!' });
          setTimeout(() => {
            setError({});
            setFirstName('');
            setLastName('');
            setEmail('');
            setIsSending(false);
          }, 3000);
        }
      })
      .catch(err => {
        setIsSending(false);
        if (err.response.status === 400) {
          if (err.response.data.message === 'email invalid') {
            return setError({ email: 'Please enter a valid email address.' });
          }
          if (err.response.data.message === 'listId invalid') {
            return setError({ form: 'Something went wrong. Please try again later!' });
            // TO-DO: Add Sentry to notify developers of a listId malfunction
          }
          if (err.response.data.message === 'member exists') {
            return setError({
              form: "You've already subscribed with that email address! Try a different one."
            });
          }
        }
        return setError({ form: 'Something went wrong. Please try again later!' });
        // TO-DO: Add Sentry to notify developers of an unknown malfunction
      });
  }, [firstName, lastName, email, page.asPath, newsletterContext.campaign]);

  const removeItem = useCallback(
    (item: string) => {
      if (cartMutate) {
        const lineItems = [item];

        cartMutate?.removeLineItems(lineItems);
      }
    },
    [cartMutate]
  );

  useEffect(closeMenu, [page.asPath, closeMenu]);
  useEffect(() => {
    if ((lineItemCount || 0) < 1 && cartIsOpen) closeCart();
  }, [lineItemCount, closeCart, cartIsOpen]);

  const isConversionRoute = CONVERSION_ROUTES.some(route => page.asPath.includes(route));

  return (
    <nav className="Nav">
      <div className="Nav__top-bar fixed top-6 z-50 w-full md:top-16 pointer-events-none">
        <div className="Nav__top-bar__inner container relative mx-auto flex justify-between">
          <div className="Nav__menu-toggle fixed top-6 right-6 mt-[5px] flex w-1/2 items-center justify-end md:absolute md:right-auto md:top-0 md:-left-6 md:mt-2 md:w-[20px] lg:left-8">
            {!!lineItemCount && (
              <div className="relative mr-4 mb-[9px] h-6 w-6 items-center justify-center md:hidden">
                <Button
                  variant="no-style"
                  onClick={openCart}
                  className="w-full pointer-events-auto"
                >
                  <BagIcon
                    className={cx('absolute h-full w-full fill-white transition-opacity', {
                      'opacity-0': computedTheme === 'black'
                    })}
                  />
                  <BagIcon
                    className={cx('absolute h-full w-full fill-charcoal transition-opacity', {
                      'opacity-0': computedTheme === 'white'
                    })}
                  />
                  <span
                    className={cx(
                      'Nav__line-item-count relative z-10 mt-2 block font-grotesk-headline text-xs',
                      {
                        'text-white': computedTheme === 'black',
                        'text-charcoal': computedTheme === 'white'
                      }
                    )}
                  >
                    {lineItemCount}
                  </span>
                </Button>
              </div>
            )}
            <div className="relative w-[20px]">
              {menuIsOpen ? (
                <button className="pointer-events-auto" onClick={closeMenu}>
                  <Image src={iconClose} alt="Close Menu Icon" />
                </button>
              ) : (
                <button className="pointer-events-auto" onClick={openMenu}>
                  <span className="absolute top-0 left-0">
                    <Image
                      className={cx('transition-opacity', {
                        'opacity-0': computedTheme !== 'black'
                      })}
                      src={iconMenuBlack}
                      alt="Menu Icon in Black"
                    />
                  </span>
                  <span className="absolute top-0 left-0">
                    <Image
                      className={cx('transition-opacity', {
                        'opacity-0': computedTheme !== 'white'
                      })}
                      src={iconMenuWhite}
                      alt="Menu Icon in White"
                    />
                  </span>
                </button>
              )}
            </div>
          </div>
          <div className="Nav__logo relative w-1/2">
            <Link href="/">
              <a
                className={cx({
                  'pointer-events-none': hasScrolledBeyondHero && !menuIsOpen,
                  'pointer-events-auto': !hasScrolledBeyondHero || menuIsOpen
                })}
              >
                <span className="absolute top-0 left-0">
                  <Image
                    className={cx('transition-opacity', {
                      'opacity-0':
                        computedTheme !== 'black' || (hasScrolledBeyondHero && !menuIsOpen)
                    })}
                    width="200"
                    height="35"
                    src={logoBlack}
                    alt="Escapod Logo Black"
                  />
                </span>
                <span className="absolute top-0 left-0">
                  <Image
                    className={cx('transition-opacity', {
                      'opacity-0':
                        computedTheme !== 'white' || (hasScrolledBeyondHero && !menuIsOpen)
                    })}
                    width="200"
                    height="35"
                    src={logoWhite}
                    alt="Escapod Logo White"
                  />
                </span>
              </a>
            </Link>
          </div>
          <div
            className={cx(
              'Nav__buttons fixed bottom-6 left-0 flex w-full px-6 md:relative md:bottom-auto md:w-1/2 md:items-center md:justify-end md:px-0',
              { 'pointer-events-none opacity-0 md:pointer-events-auto md:opacity-100': menuIsOpen }
            )}
          >
            {!!lineItemCount && (
              <div className="relative mr-6 mb-1 hidden h-7 w-7 items-center justify-center md:flex">
                <Button
                  variant="no-style"
                  className="flex items-center justify-center pointer-events-auto"
                  onClick={openCart}
                >
                  <BagIcon
                    className={cx('absolute h-full w-full fill-white transition-opacity', {
                      'opacity-0': computedTheme === 'black'
                    })}
                  />
                  <BagIcon
                    className={cx('absolute h-full w-full fill-charcoal transition-opacity', {
                      'opacity-0': computedTheme === 'white'
                    })}
                  />
                  <span
                    className={cx('relative z-10 mt-2 block font-grotesk-headline text-xs', {
                      'text-white': computedTheme === 'black',
                      'text-charcoal': computedTheme === 'white'
                    })}
                  >
                    {lineItemCount}
                  </span>
                </Button>
              </div>
            )}
            <div className="Nav__order-rentals-buttons flex nowrap w-full md:w-auto justify-center md:justify-end">
              <div className="Nav__order-now-button pr-2 w-1/2 md:w-auto hidden md:block">
                <LinkButton
                  url="/order-now/topo-2"
                  label="Order Now"
                  variant={computedTheme === 'black' ? 'primary' : 'secondary'}
                  className="w-full md:w-auto pointer-events-auto"
                />
              </div>
              <div
                className={cx('Nav__order-now-button-mobile pr-2 w-1/2 md:w-auto md:hidden', {
                  hidden: isConversionRoute
                })}
              >
                <LinkButton
                  variant="primary"
                  label="Order Now"
                  url="/order-now/topo-2"
                  className="w-full md:w-auto pointer-events-auto"
                  innerClassName="!w-full"
                />
              </div>
              <div className="Nav__rentals-button pl-2 w-1/2 md:w-auto hidden md:block">
                <LinkButton
                  url="/rentals"
                  variant={computedTheme === 'black' ? 'primary' : 'secondary'}
                  className="w-full md:w-auto pointer-events-auto"
                  label="Rentals"
                />
              </div>
              <div
                className={cx('Nav__rentals-button-mobile pl-2 w-1/2 md:w-auto md:hidden', {
                  hidden: isConversionRoute
                })}
              >
                <LinkButton
                  url="/rentals"
                  variant="primary"
                  className="w-full md:w-auto pointer-events-auto"
                  innerClassName="!w-full"
                  label="Rentals"
                />
              </div>
            </div>
          </div>
          <div
            className={cx(
              'Nav__cart container absolute top-8 right-0 z-50 w-full md:w-1/2 max-w-lg py-6 md:top-[100%] transition-all pointer-events-none',
              {
                'pointer-events-auto': cartIsOpen,
                'opacity-0 translate-y-4': !cartIsOpen
              }
            )}
          >
            <div className="Nav__cart-inner h-full w-full rounded-sm bg-stone-200 p-6">
              <div className="Nav__cart-header flex justify-between border-b border-white pb-4">
                <span className="font-grotesk-headline text-2xl tracking-wider">Cart</span>
                <button onClick={closeCart}>
                  <Image src={iconClose} alt="Close" />
                </button>
              </div>
              <ul className="Nav__cart__items relative max-h-72 overflow-auto py-6">
                {cartState?.lineItems.map(item => (
                  <li
                    key={`Nav__cart__item--${item.id}`}
                    className="Nav__cart__item mb-4 flex border-b border-white pb-4 last-of-type:mb-0 last-of-type:border-0 last-of-type:pb-0"
                  >
                    <div className="Nav__cart__item__image">
                      <div className="relative mr-4 h-16 w-16">
                        <Image
                          alt={item.title}
                          src={item.variant.image.originalSrc}
                          layout="fill"
                          objectFit="scale-down"
                        />
                      </div>
                    </div>
                    <div className="flex w-full flex-col justify-between">
                      <div className="mb-2 flex flex-col">
                        <div className="flex justify-between">
                          <span className="pr-8 font-grotesk-headline text-xs md:text-sm">
                            {item.title}
                          </span>
                          <span className="text-xs text-stone-500 md:text-sm">
                            ${(parseFloat(item.variant.price) * item.quantity).toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <span
                          className={cx('text-xs text-stone-500', {
                            'hidden ': item.variant.title === 'Default Title'
                          })}
                        >
                          {item.variant.title}
                        </span>
                        <Button
                          variant="no-style"
                          className={cx('p-0 leading-[1rem]', {
                            'text-right w-full': item.variant.title === 'Default Title'
                          })}
                        >
                          <span
                            className="font-grotesk-news text-xs font-normal text-fire"
                            onClick={() => removeItem(item.id)}
                          >
                            Remove
                          </span>
                        </Button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="Nav__cart-footer relative flex items-center justify-between border-t border-white pt-6 before:absolute before:-top-[25px] before:left-0 before:h-6 before:w-full before:bg-gradient-to-t before:from-neutral-200">
                <span className="font-grotesk-headline">
                  <span className="text-fire">Subtotal: </span>
                  <span>${cartState?.subtotalPrice}</span>
                </span>
                <a href={cartState?.webUrl} target="_blank" rel="noreferrer">
                  <Button
                    variant="primary"
                    onClick={() => {
                      Fbq('InitiateCheckout'); // TO-DO: Make this useCallback on a LinkButton
                    }}
                  >
                    Checkout
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={cx(
          'Nav__menu w100 fixed top-0 bottom-0 left-0 right-0 z-40 bg-white transition-opacity flex items-center md:-mt-24',
          {
            'opacity-0 pointer-events-none': !menuIsOpen
          }
        )}
      >
        <div className="Nav__menu__lists container h-full overflow-auto py-32 pr-2 md:h-auto md:pr-4 md:pt-40 md:pb-0">
          <ul className="flex flex-wrap">
            {menu.map(group => (
              <li
                key={group._key}
                className="mb-16 w-full pr-2 md:w-[21%] md:pr-4 md:fifths:w-[16%] md:fifths:pr-0"
              >
                <span className="mb-6 block font-grotesk-sub-headline-news text-sm uppercase tracking-[1px] text-fire">
                  {group.title}
                </span>
                {!!group.links.length && (
                  <ul>
                    {group.links.map(link => {
                      const classes =
                        'font-grotesk-news text-[11px] md:text-[13px] tracking-wider text-charcoal';

                      return (
                        <li key={link._key} className="mb-3 leading-tight">
                          {linkIsRelative(link.url) ? (
                            <Link href={link.url}>
                              <a className={classes}>{link.title}</a>
                            </Link>
                          ) : (
                            // TO-DO: New tab optional
                            <a
                              href={link.url}
                              target="_blank"
                              rel="noopner noreferrer"
                              aria-label={link.title}
                              className={classes}
                            >
                              {link.title}
                            </a>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="Nav__newsletter absolute bottom-0 left-0 hidden w-full bg-stone-200 md:block">
          <div className="Nav__newsletter__inner flex items-center p-8">
            <span className="mr-12 whitespace-nowrap font-grotesk-headline text-xs tracking-wide text-charcoal">
              Newsletter Signup
            </span>
            {error.form && (
              <div className="NewsletterSignup__form-error absolute -top-8 right-8">
                <span className="text-center text-xs text-fire">{error.form}</span>
              </div>
            )}
            <FormWrapper
              id="Escapod-Nav-Menu-Newsletter-Subscribe"
              name="Escapod-Nav-Menu-Newsletter-Subscribe"
              className="flex w-full"
            >
              <TextField
                className="mr-8"
                id="Nav-newsletter-form-first-name"
                name="first_name"
                placeholder="First Name"
                ariaLabel="First Name"
                value={firstName}
                error={error.firstName}
                showError={!!error.firstName}
                onChange={value => setFirstName(value as string)}
              />
              <TextField
                className="mr-8"
                id="Nav-newsletter-form-last-name"
                name="last_name"
                placeholder="Last Name"
                ariaLabel="Last Name"
                value={lastName}
                error={error.lastName}
                showError={!!error.lastName}
                onChange={value => setLastName(value as string)}
              />
              <TextField
                className="mr-8"
                id="Nav-newsletter-form-email"
                name="email"
                placeholder="Email Address"
                ariaLabel="Email Address"
                value={email}
                error={error.email}
                showError={!!error.email}
                onChange={value => setEmail(value as string)}
              />
              <Button
                type="button"
                variant="primary"
                onClick={submit}
                className={cx('', {
                  'cursor-not-allowed opacity-50': isSending
                })}
              >
                Signup
              </Button>
            </FormWrapper>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
