import React, { ForwardRefRenderFunction, ReactNode, MouseEvent, forwardRef } from 'react';
import cx from 'classnames';

type Props = {
  variant:
    | 'primary'
    | 'secondary'
    | 'neutral'
    | 'primary-small'
    | 'secondary-small'
    | 'neutral-small'
    | 'link'
    | 'no-style';
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit';
  children: ReactNode;
  disabled?: boolean;
};

export const Button: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  { variant, children, className = '', onClick, type = 'button', disabled },
  ref
) => {
  const baseClasses =
    'transition-all rounded-sm font-grotesk-headline tracking-wide whitespace-nowrap';
  let variantClasses: string = '';
  if (variant === 'primary') {
    variantClasses = 'h-[45px] px-[1.6rem] text-xs bg-charcoal hover:opacity-90 text-white';
  }
  if (variant === 'secondary') {
    variantClasses = 'h-[45px] px-[1.6rem] text-xs bg-white hover:bg-charcoal hover:text-white';
  }
  if (variant === 'neutral') {
    variantClasses = 'h-[45px] px-[1.6rem] text-xs bg-stone-200 hover:bg-charcoal hover:text-white';
  }
  if (variant === 'primary-small') {
    variantClasses = 'py-2 px-4 text-[8px] bg-charcoal hover:opacity-90 text-white';
  }
  if (variant === 'secondary-small') {
    variantClasses = 'py-2 px-4 text-[8px] bg-white hover:bg-charcoal hover:text-white';
  }
  if (variant === 'neutral-small') {
    variantClasses = 'py-2 px-4 text-[8px] bg-stone-200 hover:bg-charcoal hover:text-white';
  }
  if (variant === 'link') {
    variantClasses = 'text-black underline hover:opacity-90';
  }

  return (
    <button
      ref={ref}
      className={cx(className, variantClasses, baseClasses, {
        'opacity-50 pointer-events-none': disabled
      })}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default forwardRef<HTMLButtonElement, Props>(Button);
