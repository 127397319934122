import React, { FC, useCallback, useState, createContext, useContext } from 'react';
import ReactPlayer from 'react-player';
import cx from 'classnames';
import {
  PortableText as SanityPortableText,
  PortableTextComponents,
  PortableTextTypeComponentProps
} from '@portabletext/react';

import iconPlayButton from '../../public/images/icon-play-button.svg';

import { Block, PortableText as TPortableText, Image as TImage, VideoEmbed } from 'types';
import { BlockWrapper, Image, Button } from 'components';

const BLOCKS_WITH_IMAGES = ['imageBlock', 'imageListItem', 'videoBlock', 'galleryBlock'];

const captionComponents: Partial<PortableTextComponents> = {
  block: {
    normal: ({ children }) => (
      <p className="font-grotesk-sub-headline-news !leading-relaxed tracking-wide text-[.6275rem] md:text-xs text-stone-500">
        {children}
      </p>
    )
  },
  marks: {
    strong: ({ children }) => (
      <strong className="font-grotesk-headline-news tracking-wide font-normal text-[.6275rem] md:text-xs text-stone-500">
        {children}
      </strong>
    ),
    link: ({ value, children }) => {
      const target = (value?.href || '').startsWith('http') ? '_blank' : undefined;

      return (
        <a
          className="underline tracking-wide text-[.6275rem] md:text-xs text-stone-500"
          href={value?.href}
          target={target}
          rel={target === '_blank' ? 'noindex nofollow' : undefined}
        >
          {children}
        </a>
      );
    }
  }
};

const ImageBlock: FC<any> = ({
  value: { _key, image, layout, caption }
}: PortableTextTypeComponentProps<{
  _key: string;
  image: TImage;
  layout: string;
  caption: TPortableText;
}>) => {
  const imageWidth = image.metadata?.dimensions.width || 1;
  const imageHeight = image.metadata?.dimensions.height || 1;

  const blocks = useContext(LongFormContext);
  const index = blocks.findIndex(block => block._key === _key);
  const isLastOfType = blocks[index + 1]?._type !== 'imageBlock';
  const hasImageAfter = BLOCKS_WITH_IMAGES.includes(blocks[index + 1]?._type);

  if (!image || !layout) return null;

  return (
    <div
      className={cx('LongForm__ImageBlock', {
        'float-left w-2/5 md:w-1/2 my-4 mr-8 md:my-6 md:mr-12': layout === 'left',
        'float-right w-2/5 md:w-1/2 my-4 ml-8 md:my-6 md:ml-12': layout === 'right',
        'max-w-prose my-6 mx-auto border-stone-200 border-t-[1px] py-12': layout === 'column',
        'w-full my-6 mx-auto border-stone-200 border-t-[1px] py-12': layout === 'full-width',
        ' border-b-[1px]':
          (!hasImageAfter && isLastOfType && layout === 'column') ||
          (!hasImageAfter && isLastOfType && layout === 'full-width')
      })}
    >
      <div
        className="LongForm__ImageBlock__image relative"
        style={{
          paddingBottom: `${(imageHeight / imageWidth) * 100}%`
        }}
      >
        <Image src={image.src} layout="fill" alt={image.alt} />
      </div>
      {!!caption && (
        <div className="LongForm__ImageBlock__caption w-full pt-4">
          <SanityPortableText value={caption} components={captionComponents} />
        </div>
      )}
    </div>
  );
};

const GalleryBlock: FC<any> = ({
  value: { _key, items, layout }
}: PortableTextTypeComponentProps<{
  _key: string;
  items: {
    _key: string;
    image: TImage;
    caption?: TPortableText;
  }[];
  layout: 'column' | 'full-width';
}>) => {
  const blocks = useContext(LongFormContext);
  const index = blocks.findIndex(block => block._key === _key);
  const isLastOfType = blocks[index + 1]?._type !== 'galleryBlock';
  const hasImageAfter = BLOCKS_WITH_IMAGES.includes(blocks[index + 1]?._type);

  if (!items || !layout) return null;

  return (
    <div
      className={cx('LongForm__GalleryBlock', 'border-t-stone-200 border-t-[1px] pt-12', {
        'max-w-prose my-12 mx-auto': layout === 'column',
        'w-full my-12 mx-auto': layout === 'full-width',
        'border-b-[1px] border-stone-200 pb-12': isLastOfType && !hasImageAfter
      })}
    >
      <div className="GalleryBlock__items flex flex-wrap md:justify-between md:flex-nowrap">
        {items.map(item => (
          <div
            className={cx(
              'GalleryBlock__items__item w-1/2 flex-grow md:flex-grow-0 px-1 md:px-0 md:w-[32%]',
              {
                'md:!w-[49%]': items.length <= 2
              }
            )}
            key={`GalleryBlock__image-${item._key}`}
          >
            <div
              className="GalleryBlock__items__item__image relative"
              style={{
                paddingBottom: `${
                  ((item.image.metadata?.dimensions.height || 1) /
                    (item.image.metadata?.dimensions.width || 1)) *
                  100
                }%`
              }}
            >
              <Image src={item.image.src} layout="fill" alt={item.image.alt} />
            </div>
            {!!item.caption && (
              <div className="GalleryBlock__items__item__caption mt-4">
                <SanityPortableText value={item.caption} components={captionComponents} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const ImageListItem: FC<any> = ({
  value: { _key, layout, imagePosition, image, body }
}: PortableTextTypeComponentProps<{
  _key: string;
  layout: 'column' | 'full-width';
  imagePosition: 'left' | 'right';
  image: TImage;
  body: TPortableText;
}>) => {
  const imageWidth = image.metadata?.dimensions.width || 1;
  const imageHeight = image.metadata?.dimensions.height || 1;

  const blocks = useContext(LongFormContext);
  const index = blocks.findIndex(block => block._key === _key);
  const isLastOfType = blocks[index + 1]?._type !== 'imageListItem';
  const hasImageAfter = BLOCKS_WITH_IMAGES.includes(blocks[index + 1]?._type);

  if (!image || !body || !imagePosition || !layout) return null;

  return (
    <div className="LongForm__ImageListItem">
      <div
        className={cx(
          'ImageListItem__inner-wrapper border-t-[1px] border-stone-200 md:flex md:flex-nowrap md:items-center py-12',
          {
            'md:flex-row-reverse': imagePosition === 'right',
            'max-w-prose mx-auto': layout === 'column',
            'w-full mx-auto': layout === 'full-width',
            'border-b-[1px] mb-12': isLastOfType && !hasImageAfter
          }
        )}
      >
        <div className="ImageListItem__image-wrapper md:w-1/2">
          <div
            className="ImageListItem__image relative"
            style={{
              paddingBottom: `${((imageHeight || 1) / (imageWidth || 1)) * 100}%`
            }}
          >
            <Image src={image.src} layout="fill" alt={image.alt} />
          </div>
        </div>
        <div className="ImageListItem__body px-6 pt-12 md:pt-6 md:pb-6 -mb-6 md:w-1/2">
          <SanityPortableText value={body} />
        </div>
      </div>
    </div>
  );
};

const VideoBlock: FC<any> = ({
  value: { _key, title, thumbnail, videoEmbed, videoButton, layout }
}: PortableTextTypeComponentProps<{
  _key: string;
  title: string;
  layout: 'column' | 'full-width';
  thumbnail: TImage;
  videoEmbed: VideoEmbed;
  videoButton: { url: string; label: string };
}>) => {
  const [thumbnailIsHidden, setThumbnailIsHidden] = useState<boolean>(false);
  const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(false);

  const onPlayButtonClick = useCallback(() => {
    setThumbnailIsHidden(true);
    setVideoIsPlaying(true);
  }, []);

  const handleVideoButtonClick = () => window.open(videoButton?.url);

  const blocks = useContext(LongFormContext);
  const index = blocks.findIndex(block => block._key === _key);
  const isLastOfType = blocks[index + 1]?._type !== 'videoBlock';
  const hasImageAfter = BLOCKS_WITH_IMAGES.includes(blocks[index + 1]?._type);

  if (!layout || !thumbnail || !videoEmbed.url || !videoEmbed.width || !videoEmbed.height)
    return null;

  return (
    <div
      className={cx('VideoBlock__wrapper border-t-[1px] border-stone-200 py-12', {
        'mb-28 lg:mb-24': !!videoButton,
        'max-w-prose my-12 mx-auto': layout === 'column',
        'w-full my-12 mx-auto': layout === 'full-width',
        'border-b-[1px]': isLastOfType && !hasImageAfter
      })}
    >
      <div className="VideoBlock__inner-wrapper relative">
        <div className="VideoBlock__title-button-wrapper lg:flex lg:flex-nowrap lg:justify-between lg:items-center pb-4 lg:pb-8">
          {!!title && (
            <div className="VideoBlock__title mb-4 lg:mb-0">
              <span className="font-grotesk-headline-news text-lg md:text-xl">{title}</span>
            </div>
          )}
          {!!videoButton && (
            <div className="VideoBlock__button absolute -bottom-16 left-0 lg:relative lg:bottom-0">
              <Button variant="primary" onClick={() => handleVideoButtonClick()}>
                {videoButton?.label}
              </Button>
            </div>
          )}
        </div>
        <div
          className="VideoBlock__player-wrapper relative"
          style={{ paddingBottom: `${(videoEmbed.height / videoEmbed.width) * 100}%` }}
        >
          <ReactPlayer
            url={videoEmbed.url}
            className="VideoBlock__player absolute top-0 left-0"
            width="100%"
            height="100%"
            playing={videoIsPlaying}
          />
          <div
            className={cx(
              'VideoBlock__thumbnail-wrapper absolute flex h-full w-full items-center justify-center bg-black transition-opacity',
              {
                'pointer-events-none opacity-0': thumbnailIsHidden
              }
            )}
          >
            <Image
              className="VideoBlock__thumbnail opacity-80"
              src={thumbnail.src}
              alt={thumbnail.alt}
              layout="fill"
              objectFit="cover"
            />
            <Button
              variant="no-style"
              className="VideoBlock__play-button-wrapper absolute flex items-center justify-center md:bottom-9 md:left-9 md:hover:brightness-200 lg:bottom-10 lg:left-10"
              onClick={onPlayButtonClick}
            >
              <div className="VideoBlock__play-button-icon mr-2 w-11 md:w-12 md:brightness-90 lg:w-16">
                <Image
                  src={iconPlayButton}
                  alt="Video play button icon"
                  hideLoadingPlaceholder={true}
                />
              </div>
              <span className="VideoBlock__play-button-text font-grotesk-headline text-base text-white">
                Watch Video
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const components: Partial<PortableTextComponents> = {
  types: {
    imageBlock: ImageBlock,
    galleryBlock: GalleryBlock,
    imageListItem: ImageListItem,
    videoBlock: VideoBlock
  },
  block: {
    normal: ({ children }) => {
      return (
        <div className="lg:max-w-prose mx-auto mb-6">
          <p className="tracking-wide text-md md:text-lg !leading-loose">{children}</p>
        </div>
      );
    },
    h2: ({ children }) => (
      <div className="lg:max-w-prose mx-auto my-12">
        <h2 className="font-grotesk-headline-news tracking-wide text-xl md:text-2xl">{children}</h2>
      </div>
    ),
    h3: ({ children }) => (
      <div className="lg:max-w-prose mx-auto mb-6 mt-12">
        <h3 className="font-grotesk-headline-news tracking-wide text-lg md:text-xl">{children}</h3>
      </div>
    ),
    h4: ({ children }) => (
      <div className="lg:max-w-prose mx-auto mb-6">
        <h4 className="font-grotesk-headline-news tracking-wide text-md md:text-lg">{children}</h4>
      </div>
    ),
    h5: ({ children }) => (
      <div className="lg:max-w-prose mx-auto mb-6">
        <h5 className="font-grotesk-sub-headline tracking-wide text-md md:text-lg text-fire">
          {children}
        </h5>
      </div>
    ),
    h6: ({ children }) => (
      <div className="lg:max-w-prose mx-auto mb-6">
        <h6 className="tracking-wide text-md md:text-lg text-fire">{children}</h6>
      </div>
    ),
    blockquote: ({ children }) => (
      <div className="lg:max-w-prose mx-auto mb-6 border-stone-200 border-l-2 pl-6">
        <blockquote className="tracking-wide text-md md:text-lg !leading-loose text-stone-400">
          {children}
        </blockquote>
      </div>
    )
  },
  marks: {
    strong: ({ children }) => (
      <strong className="font-grotesk-headline-news font-normal tracking-wide">{children}</strong>
    ),
    link: ({ value, children }) => {
      const target = (value?.href || '').startsWith('http') ? '_blank' : undefined;

      return (
        <a
          className="underline text-fire tracking-wide"
          href={value?.href}
          target={target}
          rel={target === '_blank' ? 'noindex nofollow' : undefined}
        >
          {children}
        </a>
      );
    }
  },
  list: {
    number: ({ children }) => (
      <div className="lg:max-w-prose mx-auto mb-6">
        <ol className="pl-8 list-decimal [&_ol]:list-[lower-alpha]">{children}</ol>
      </div>
    ),
    bullet: ({ children }) => (
      <div className="lg:max-w-prose mx-auto mb-6">
        <ul className="pl-4 list-disc">{children}</ul>
      </div>
    )
  },
  listItem: ({ children }) => (
    <li className=" pl-2 tracking-wide text-md md:text-lg !leading-loose">{children}</li>
  )
};

const LongFormContext = createContext<any[]>([]);

export type TLongForm = Block<
  'longForm',
  {
    content: TPortableText;
  }
>;
export const LongForm: FC<TLongForm> = ({ content }) => (
  <BlockWrapper className="LongForm first-letter:text-3xl first-letter:font-grotesk-headline first-letter:text-fire">
    <LongFormContext.Provider value={content}>
      <SanityPortableText value={content} components={components} />
    </LongFormContext.Provider>
  </BlockWrapper>
);

export default LongForm;
