import React, { RefObject, KeyboardEvent, useCallback } from 'react';
import cx from 'classnames';

type Props = {
  id?: string;
  name: string;
  label?: string;
  className?: string;
  inputClassName?: string;
  ariaLabel: string;
  onBlur?: (e: string | number) => any;
  onFocus?: (e: string | number) => any;
  onChange?: (e: string | number) => any;
  onKeyDown?: (e: KeyboardEvent) => any;
  pattern?: string;
  placeholder?: string;
  required?: boolean;
  autoComplete?: boolean;
  maxLength?: number;
  type?: 'email' | 'text' | 'tel' | 'number' | 'password' | 'textarea' | 'date';
  value?: string | number;
  min?: number | string;
  max?: number | string;
  variant?: 'primary';
  validate?: () => undefined | string;
  disabled?: boolean;
  readOnly?: boolean;
  elemRef?: RefObject<HTMLInputElement | HTMLTextAreaElement> | ((ref: HTMLInputElement) => void);
  error?: string;
  showError?: boolean;
};

const TextField: React.FC<Props> = ({
  id,
  name,
  label,
  className = '',
  inputClassName = '',
  ariaLabel,
  pattern,
  placeholder,
  required = false,
  autoComplete = false,
  maxLength,
  type = 'text',
  value,
  min,
  max,
  variant = 'primary',
  disabled = false,
  readOnly = false,
  elemRef,
  onBlur = () => {},
  onFocus = () => {},
  onChange = () => {},
  onKeyDown = () => {},
  error,
  showError = false
}) => {
  const _id = id || name;

  return (
    <div
      className={cx('TextField relative w-full', className, {
        [`TextField--style-${variant}`]: !!variant,
        'TextField--filled': !!value,
        'TextField--errored': error,
        'TextField--disabled': disabled
      })}
    >
      {label && (
        <label
          className="TextField__label mb-2 block font-grotesk-headline text-xs tracking-wide"
          htmlFor={_id}
        >
          {label}
        </label>
      )}
      {type === 'textarea' ? (
        <textarea
          className={cx(
            'TextField__textarea form-input w-full rounded-sm px-4 py-3 text-sm tracking-wide placeholder-stone-400',
            inputClassName,
            {
              'border-stone-300': !showError,
              'border-fire': showError
            }
          )}
          aria-label={ariaLabel}
          id={_id}
          name={name}
          onBlur={e => onBlur(e.target.value as string)}
          onFocus={e => onFocus(e.target.value as string)}
          onChange={e => onChange(e.target.value as string)}
          onKeyDown={e => onKeyDown(e)}
          placeholder={placeholder}
          required={required}
          maxLength={maxLength}
          value={value}
          autoComplete={autoComplete ? 'on' : 'off'}
          disabled={disabled}
          readOnly={readOnly}
          ref={elemRef as RefObject<HTMLTextAreaElement>}
          aria-invalid={!!error}
        />
      ) : (
        <input
          className={cx(
            'TextField__input form-input w-full rounded-sm px-4 py-3 text-sm tracking-wide placeholder-stone-400',
            {
              'border-stone-200': !showError,
              'border-fire': showError
            }
          )}
          aria-label={ariaLabel}
          id={_id}
          name={name}
          onBlur={e => onBlur(e.target.value as string)}
          onFocus={e => onFocus(e.target.value as string)}
          onChange={e => onChange(e.target.value as string)}
          onKeyDown={e => onKeyDown(e)}
          pattern={pattern}
          placeholder={placeholder}
          type={type}
          min={min}
          max={max}
          required={required}
          maxLength={maxLength}
          value={value}
          autoComplete={autoComplete ? 'on' : 'off'}
          disabled={disabled}
          readOnly={readOnly}
          ref={elemRef as RefObject<HTMLInputElement>}
          aria-invalid={!!error}
        />
      )}

      {error && (
        <label
          htmlFor={_id}
          className={cx(
            'TextField__error text-error tracking-wide absolute top-[100%] mt-1 ml-1 block text-[.625rem] md:text-xs text-fire lg:-bottom-6 lg:left-0 lg:ml-1',
            {
              'opacity-0': !showError,
              'opacity-1': showError
            }
          )}
          role="alert"
        >
          {error}
        </label>
      )}
    </div>
  );
};

export default TextField;
