import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import { useNavContext } from 'contexts/NavContext';

import {
  ProductGrid,
  ProductHero,
  ProductCards,
  ImageText,
  TextBlock,
  Hero,
  LongForm,
  Video,
  AccordionList,
  NewsletterSignup,
  AccordionListSearch,
  ImageCarousel,
  ThreeSixty,
  Compare,
  TwoImageGallery,
  CartBlock,
  Builder,
  RentalBookings,
  MapImage,
  FeatureList,
  LinkList,
  PostHero,
  ContactFormBlock,
  PostGrid,
  BlockTypes,
  RecirculationLinkCards,
  FinancingForm
} from 'components';

type Props = {
  blocks: BlockTypes[];
};

const Content: FC<Props> = ({ blocks }) => {
  const navContext = useNavContext();
  const firstBlockIsHero = !!blocks && !!blocks[0] && blocks[0]._type === 'hero';

  useEffect(() => {
    if (!firstBlockIsHero && navContext.theme !== 'black') {
      navContext.setContext({ ...navContext, theme: 'black' });
    }
  }, [navContext, firstBlockIsHero]);

  return (
    <div
      className={cx('Content relative min-h-screen overflow-auto', {
        'pt-32 lg:pt-48': !firstBlockIsHero
      })}
    >
      {!!blocks &&
        blocks.map((block, i) => {
          const key = block._key;

          const meta = {
            isFirstBlockWithTitle:
              blocks.findIndex(
                block => block._type !== 'hero' && 'title' in block && !!block.title
              ) === i,
            isLastOfConsecutiveType: !blocks[i + 1] || blocks[i + 1]._type !== block._type,
            nextBlockType: !!blocks[i + 1] && blocks[i + 1]._type
          };

          switch (block._type) {
            case 'cart':
              return <CartBlock key={key} />;
            case 'imageText':
              return <ImageText key={key} {...block} meta={meta} />;
            case 'textBlock':
              return <TextBlock key={key} {...block} meta={meta} />;
            case 'hero':
              return <Hero key={key} {...block} meta={meta} />;
            case 'postHero':
              return <PostHero key={key} {...block} meta={meta} />;
            case 'postGrid':
              return <PostGrid key={key} {...block} meta={meta} />;
            case 'video':
              return <Video key={key} {...block} meta={meta} />;
            case 'accordionList':
              return <AccordionList key={key} {...block} meta={meta} />;
            case 'contactFormBlock':
              return <ContactFormBlock key={key} {...block} meta={meta} />;
            case 'newsletterSignup':
              return <NewsletterSignup key={key} {...block} meta={meta} />;
            case 'accordionListSearch':
              return <AccordionListSearch key={key} {...block} meta={meta} />;
            case 'imageCarousel':
              return <ImageCarousel key={key} {...block} meta={meta} />;
            case 'threeSixty':
              return <ThreeSixty key={key} {...block} meta={meta} />;
            case 'compare':
              return <Compare key={key} {...block} meta={meta} />;
            case 'longForm':
              return <LongForm key={key} {...block} meta={meta} />;
            case 'twoImageGallery':
              return <TwoImageGallery key={key} {...block} meta={meta} />;
            case 'productGrid':
              return <ProductGrid key={key} {...block} meta={meta} />;
            case 'productHero':
              return <ProductHero key={key} {...block} meta={meta} />;
            case 'productCards':
              return <ProductCards key={key} {...block} meta={meta} />;
            case 'builder':
              return <Builder key={key} {...block} meta={meta} />;
            case 'rentalBookings':
              return <RentalBookings key={key} {...block} meta={meta} />;
            case 'mapImage':
              return <MapImage key={key} {...block} meta={meta} />;
            case 'featureList':
              return <FeatureList key={key} {...block} meta={meta} />;
            case 'linkList':
              return <LinkList key={key} {...block} meta={meta} />;
            case 'recirculationLinkCards':
              return <RecirculationLinkCards key={key} {...block} meta={meta} />;
            case 'financingForm':
              return <FinancingForm key={key} {...block} meta={meta} />;
            default:
              return null;
          }
        })}
    </div>
  );
};

export default Content;
