import React, { FC, useCallback, useState } from 'react';
import EscapodApi from 'clients/Escapod';
import Fbq from 'clients/Fbq';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';

import { Block, PortableText as TPortableText } from 'types';
import { BlockWrapper, Button, PortableText, TextField, FormWrapper, Select } from 'components';
import emailIsValid from 'utils/emailIsValid';

type FormData = {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
};
const INITIAL_FORM_DATA = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  message: ''
};

export type TContactFormBlock = Block<
  'contactFormBlock',
  {
    title?: string;
    layout: 'dropdown';
    variant?: 'legalInfo';
    body: TPortableText;
    buttonLabel: string;
  }
>;
export const ContactFormBlock: FC<TContactFormBlock> = ({
  title,
  layout,
  variant,
  body,
  buttonLabel
}) => {
  const [formIsOpen, setFormIsOpen] = useState(false);
  const openForm = useCallback(() => setFormIsOpen(true), [setFormIsOpen]);

  const [formIsSending, setFormIsSending] = useState(false);

  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA);
  const [formErrors, setFormErrors] = useState<FormData & { form?: string }>(INITIAL_FORM_DATA);

  const submitForm = useCallback(() => {
    setFormErrors(INITIAL_FORM_DATA);

    if (!formData.name) {
      return setFormErrors({ ...INITIAL_FORM_DATA, name: 'Please enter a name.' });
    }
    if (!formData.email || !emailIsValid(formData.email)) {
      return setFormErrors({ ...INITIAL_FORM_DATA, email: 'Please enter a valid email.' });
    }
    if (!formData.phone) {
      return setFormErrors({ ...INITIAL_FORM_DATA, phone: 'Please enter a valid phone number.' });
    }
    if (!formData.subject) {
      return setFormErrors({ ...INITIAL_FORM_DATA, subject: 'Please select a support category.' });
    }
    if (!formData.message) {
      return setFormErrors({
        ...INITIAL_FORM_DATA,
        message: 'Please tell us what we can help you with.'
      });
    }

    setFormIsSending(true);

    const createContact = async () => {
      const response = await EscapodApi.contact.create({
        templateParams: {
          NAME: formData.name,
          EMAIL: formData.email,
          PHONE: formData.phone,
          SUBJECT: formData.subject,
          MESSAGE: formData.message
        }
      });

      if (response.message === 'ok') {
        setFormErrors(state => ({ ...state, form: 'Your message has been received.' }));
        setTimeout(() => {
          setFormIsSending(false);
          setFormData(INITIAL_FORM_DATA);
          setFormErrors(INITIAL_FORM_DATA);
        }, 3000);
      } else {
        setFormErrors(state => ({
          ...state,
          form: 'There was an unexpected error while submitting your message. Contact us directly by emailing hello@escapod.us or try again later.'
        }));

        setFormIsSending(false);
      }
    };

    Fbq('Contact');

    createContact();
  }, [formData, setFormErrors]);

  return (
    <BlockWrapper className="ContactFormBlock">
      <div className="ContactFormBlock__inner md:pr-16">
        {!!title && (
          <div className="ContactFormBlock__title font-grotesk-headline-news text-2xl md:text-3xl">
            {title}
          </div>
        )}
        <div
          className={cx(
            'ContactFormBlock__body my-6 text-lg !leading-relaxed tracking-wider md:text-[22px] md:leading-loose lg:my-8',
            {
              '!text-sm text-stone-400 !leading-normal': variant === 'legalInfo'
            }
          )}
        >
          <PortableText content={body} />
        </div>
        <FormWrapper id="Contact-Form-Block" name="Contact-Form-Block">
          <AnimateHeight height={formIsOpen ? 'auto' : 0}>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
              <TextField
                ariaLabel="Name"
                label="Name"
                name="name"
                id="ContantFormBlock-name"
                placeholder="Full Name"
                value={formData.name}
                showError={!!formErrors.name}
                onBlur={() => setFormErrors(state => ({ ...state, name: '' }))}
                onChange={value => setFormData({ ...formData, name: value as string })}
              />
              <TextField
                ariaLabel="Email"
                label="Email"
                name="email"
                id="ContantFormBlock-email"
                placeholder="hello@escapod.us"
                value={formData.email}
                showError={!!formErrors.email}
                onBlur={() => setFormErrors(state => ({ ...state, email: '' }))}
                onChange={value => setFormData({ ...formData, email: value as string })}
              />
              <TextField
                ariaLabel="Phone Number"
                label="Phone"
                name="name"
                id="ContantFormBlock-phone"
                placeholder="435-555-0123"
                value={formData.phone}
                showError={!!formErrors.phone}
                onBlur={() => setFormErrors(state => ({ ...state, phone: '' }))}
                onChange={value => setFormData({ ...formData, phone: value as string })}
              />

              <Select
                label="Subject"
                ariaLabel="Subject"
                name="subject"
                value={formData.subject}
                showError={!!formErrors.subject}
                onBlur={() => setFormErrors(state => ({ ...state, subject: '' }))}
                onChange={value => setFormData({ ...formData, subject: value as string })}
              >
                <option value=""></option>
                <option value="sales">Sales</option>
                <option value="rentals">Rentals</option>
                <option value="careers">Careers</option>
                <option value="press">Press/Partnerships</option>
              </Select>
            </div>
            <div className="w-full mt-6">
              <TextField
                ariaLabel="How can we help? Write your message"
                label="How can we help?"
                name="name"
                type="textarea"
                id="ContantFormBlock-message"
                inputClassName="h-48"
                placeholder="Write your message here"
                value={formData.message}
                showError={!!formErrors.message}
                onBlur={() => setFormErrors(state => ({ ...state, message: '' }))}
                onChange={value => setFormData({ ...formData, message: value as string })}
              />
            </div>
          </AnimateHeight>
          {Object.values(formErrors).map(error => (
            <div key={`ContactFormError--${error}`} className="mt-2">
              <span className="text-xs text-fire tracking-wide">{error}</span>
            </div>
          ))}
          <div className="ContactFormBlock__buttons flex flex-wrap mt-6">
            <div className="ContactFormBlock__primary-button w-full mb-4">
              <Button
                variant="primary"
                className="inline"
                disabled={formIsSending}
                onClick={formIsOpen ? submitForm : openForm}
              >
                {formIsOpen ? 'Submit' : buttonLabel}
              </Button>
            </div>
          </div>
        </FormWrapper>
      </div>
    </BlockWrapper>
  );
};

export default ContactFormBlock;
