import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';

import AnimateHeight from 'react-animate-height';
import Image from 'next/image';
import iconPlus from '../../public/images/icon-plus.png';

import { Block, PortableText as TPortableText } from 'types';
import { BlockWrapper, PortableText, Button } from 'components';

import { useSearchContext } from 'contexts/SearchContext';

export type TAccordionList = Block<
  'accordionList',
  {
    title: string;
    items: {
      _key: string;
      title: string;
      body: TPortableText;
    }[];
  }
>;

export const AccordionList: FC<TAccordionList> = props => {
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const { search, accordionBlocks, setSearch } = useSearchContext();

  const filteredItems = props.items.filter(item => {
    return JSON.stringify(item).includes(search);
  });

  // TO-DO Re-write this so that the type is meaningful
  useEffect(() => {
    setSearch((prevData: any) => ({
      ...prevData,
      accordionBlocks: {
        ...prevData.accordionBlocks,
        [props._key]: props
      }
    }));
  }, [props, setSearch]);

  if (!filteredItems.length) return null;

  return (
    <BlockWrapper className="AccordionList">
      <h2
        className="AccordionList__section-title mb-6 text-center md:text-left"
        // @ts-ignore
        name={`AccordionList__${props._key}`}
      >
        <span className="font-grotesk-sub-headline text-base text-fire md:text-xl lg:text-2xl">
          {props.title}
        </span>
      </h2>
      <div className="AccordionList__item-list-wrapper">
        {filteredItems.map(item => {
          return (
            <div
              className="AccordionList__title-body-wrapper relative border-t border-stone-200 last:border-b"
              key={`AccordionList-item-${item._key}`}
            >
              <Button
                variant="no-style"
                className="w-full"
                onClick={
                  activeItem === item._key
                    ? () => setActiveItem(null)
                    : () => setActiveItem(item._key)
                }
              >
                <div className="AccordionList__item__title-icon-button-wrapper my-5 flex items-center text-left">
                  <div className="AccordionList__item__title pr-8">
                    <span
                      className={cx(
                        'whitespace-normal font-grotesk text-sm font-normal transition-colors md:text-base',
                        {
                          'text-fire': activeItem === item._key
                        }
                      )}
                    >
                      {item.title}
                    </span>
                  </div>
                  <div
                    className={cx(
                      'AccordionList__item__plus-icon absolute right-0 top-2 h-7 w-7 origin-center transition-transform md:top-4',
                      {
                        'rotate-45': activeItem === item._key
                      }
                    )}
                  >
                    <Image src={iconPlus} alt="Collapse and expand icon" />
                  </div>
                </div>
              </Button>
              <AnimateHeight height={activeItem === item._key ? 'auto' : 0} duration={500}>
                <div className="AccordionList__item__body pb-5 pr-14 text-left">
                  <span className="font-grotesk text-xs !leading-5 md:text-sm whitespace-normal">
                    <PortableText content={item.body} />
                  </span>
                </div>
              </AnimateHeight>
            </div>
          );
        })}
      </div>
    </BlockWrapper>
  );
};

export default AccordionList;
