import React, { FC } from 'react';
import cx from 'classnames';

import { Block, LinkCard as TLinkCard } from 'types';
import { BlockWrapper, LinkCard } from 'components';

export type TRecirculationLinkCards = Block<
  'recirculationLinkCards',
  {
    links: TLinkCard[];
  }
>;

export const RecirculationLinkCards: FC<TRecirculationLinkCards> = ({ links }) => {
  return (
    <BlockWrapper layout="prose" className="RecirculationLinkCards">
      <div
        className={cx('RecirculationLinkCards__LinkCards-wrapper', {
          'lg:grid lg:grid-cols-2 lg:gap-4': links.length >= 2
        })}
      >
        {links.map(link => (
          <div
            className={cx('RecirculationLinkCards__LinkCard', {
              'mb-4 last-of-type:!mb-0 lg:!mb-0': links.length > 1
            })}
            key={`RecirculationLinkCards__LinkCard__${link._key}`}
          >
            <LinkCard link={link} />
          </div>
        ))}
      </div>
    </BlockWrapper>
  );
};

export default RecirculationLinkCards;
