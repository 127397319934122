import React, { FC } from 'react';
import Image from 'next/image';

import { Block, Image as TImage } from 'types';
import { BlockWrapper } from 'components';

export type TImageCarousel = Block<
  'imageCarousel',
  {
    title?: string;
    images: {
      image: TImage;
    }[];
  }
>;

export const ImageCarousel: FC<TImageCarousel> = props => {
  props.images.map(item => {
    console.log('this is item', item);
  });

  return (
    <BlockWrapper className="ImageCarousel">
      <div className="ImageCarousel-title-wrapper">
        <span className="ImageCarousel-title">{props.title}</span>
      </div>
      {props.images.map(item => (
        <div
          key={`ImageCarousel__image--${item.image.src}`}
          className="ImageCarousel-image-wrapper"
        >
          {/* <Image src={item.image.src} layout="fill" /> */}
        </div>
        // console.log('this is src', item.image.src);
      ))}
    </BlockWrapper>
  );
};

export default ImageCarousel;
