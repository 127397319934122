import React, { FC, RefObject, KeyboardEvent } from 'react';
import cx from 'classnames';

type Props = {
  id?: string;
  name: string;
  label?: string;
  className?: string;
  ariaLabel: string;
  onBlur?: (e: string | number) => any;
  onFocus?: (e: string | number) => any;
  onChange?: (e: string | number) => any;
  placeholder?: string;
  required?: boolean;
  value?: string | number;
  variant?: 'primary' | 'secondary';
  validate?: () => undefined | string;
  disabled?: boolean;
  elemRef?: RefObject<HTMLSelectElement> | ((ref: HTMLSelectElement) => void);
  error?: string;
  showError?: boolean;
};

const Select: FC<Props> = ({
  id,
  name,
  label,
  className = '',
  ariaLabel,
  placeholder,
  required = false,
  value,
  variant = 'primary',
  disabled = false,
  elemRef,
  onBlur = () => {},
  onFocus = () => {},
  onChange = () => {},
  error,
  showError = true,
  children
}) => {
  const _id = id || name;

  return (
    <div
      className={cx('Select relative w-full', className, {
        [`Select--style-${variant}`]: !!variant,
        'Select--filled': !!value,
        'Select--errored': error,
        'Select--disabled': disabled
      })}
    >
      {label && (
        <label
          className={cx('Select__label mb-2 block', {
            'font-grotesk-headline text-xs tracking-wide': variant === 'primary',
            'font-grotesk-headline-news text-xs uppercase tracking-widest text-stone-400':
              variant === 'secondary'
          })}
          htmlFor={_id}
        >
          {label}
        </label>
      )}
      <select
        className={cx(
          'Select__input form-select w-full rounded-sm py-3 pl-4 pr-8 text-sm tracking-wide',
          {
            'border-stone-200': !showError,
            'border-fire': showError
          }
        )}
        aria-label={ariaLabel}
        id={_id}
        name={name}
        onBlur={e => onBlur(e.target.value as string)}
        onFocus={e => onFocus(e.target.value as string)}
        onChange={e => onChange(e.target.value as string)}
        placeholder={placeholder}
        required={required}
        value={value}
        disabled={disabled}
        ref={elemRef as RefObject<HTMLSelectElement>}
        aria-invalid={!!error}
      >
        {children}
      </select>
      {error && (
        <label
          htmlFor={_id}
          className={cx(
            'Select__error text-error tracking-wide absolute top-[100%] mt-1 ml-1 block text-[.625rem] md:text-xs text-fire lg:-bottom-6 lg:left-0 lg:ml-1',
            {
              'opacity-0': !showError,
              'opacity-1': showError
            }
          )}
          role="alert"
        >
          {error}
        </label>
      )}
    </div>
  );
};

export default Select;
