import React, { FC } from 'react';
import Link from 'next/link';

import { Block, Image as TImage, PortableText as TPortableText } from 'types';
import { BlockWrapper, LinkButton, PortableText, Image } from 'components';

export type TMapImage = Block<
  'mapImage',
  {
    title: string;
    body: TPortableText;
    image: TImage;
    col1Title: string;
    col1Text: TPortableText;
    directionsButton: { url: string; label: string };
    col2Title: string;
    col2Text: TPortableText;
    col3Title: string;
    col3Text: TPortableText;
  }
>;

export const MapImage: FC<TMapImage> = ({
  title,
  body,
  image,
  col1Title,
  col1Text,
  directionsButton,
  col2Title,
  col2Text,
  col3Title,
  col3Text,
  meta
}) => {
  const imageWidth = image.metadata?.dimensions.width || 1;
  const imageHeight = image.metadata?.dimensions.height || 1;

  return (
    <BlockWrapper>
      <div className="MapImage__title-body-wrapper md:pr-16 mb-10 md:mb-20">
        <div className="MapImage__title">
          {!!title && meta.isFirstBlockWithTitle && (
            <h1 className="font-grotesk-headline-news text-2xl md:text-3xl lg:text-4xl">{title}</h1>
          )}
          {!!title && !meta.isFirstBlockWithTitle && (
            <h2 className="font-grotesk-headline-news text-2xl md:text-3xl lg:text-4xl">{title}</h2>
          )}
        </div>
        <div className="MapImage__body my-6 text-sm !leading-relaxed tracking-wider md:text-xl md:leading-loose lg:my-8 lg:text-2xl">
          <PortableText content={body} />
        </div>
      </div>
      <div
        className="MapImage__image relative mb-8"
        style={{
          paddingBottom: `${(imageHeight / imageWidth) * 100}%`
        }}
      >
        <Image src={image.src} alt={image.alt} layout="fill" />
      </div>
      <div className="MapImage__text-columns flex flex-wrap lg:flex-nowrap md:w-full md:justify-between lg:px-20">
        <div className="MapImage__col1 w-full my-8 md:px-5 md:w-1/2">
          <div className="MapImage__col1__col1Title mb-6">
            <span className="font-grotesk-headline-news text-2xl md:text-3xl">{col1Title}</span>
          </div>
          <div className="MapImage__col1__col1Text mb-6 text-xs md:text-lg">
            <PortableText content={col1Text} />
          </div>
          <div className="MapImage__col1__button">
            <LinkButton
              variant="primary"
              label={directionsButton.label}
              url={directionsButton.url}
            />
          </div>
        </div>
        <div className="MapImage__col2 w-full my-8 md:px-5 md:w-1/2">
          <div className="MapImage__col2Title mb-6">
            <span className="font-grotesk-headline-news text-2xl md:text-3xl">{col2Title}</span>
          </div>
          <div className="MapImage__col2Text text-xs md:text-lg">
            <PortableText content={col2Text} />
          </div>
        </div>
        <div className="MapImage__col3 w-full my-8 md:px-5 md:w-1/2">
          <div className="MapImage__col3Title mb-6">
            <span className="font-grotesk-headline-news text-2xl md:text-3xl">{col3Title}</span>
          </div>
          <div className="MapImage__col3Text text-xs md:text-lg">
            <PortableText content={col3Text} />
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default MapImage;
