import React, { FC } from 'react';
import cx from 'classnames';

import { Block, Image as TImage, PortableText as TPortableText } from 'types';
import { BlockWrapper, Image, LinkButton, PortableText } from 'components';

export type TImageText = Block<
  'imageText',
  {
    title: string;
    layout: 'imageLeft' | 'imageRight';
    body: TPortableText;
    image: TImage;
    buttons: {
      primary?: { url: string; label: string };
      secondary?: { url: string; label: string };
    };
  }
>;

export const ImageText: FC<TImageText> = ({ title, layout, body, image, buttons, meta }) => {
  const imageWidth = image.metadata?.dimensions.width || 1;
  const imageHeight = image.metadata?.dimensions.height || 1;
  return (
    <BlockWrapper
      layout="no-style"
      className={cx('ImageText relative lg:container lg:flex lg:flex-wrap', {
        'lg:flex-row-reverse': layout === 'imageRight',
        '!mb-8': meta.nextBlockType === 'twoImageGallery'
      })}
    >
      {!!title && meta.isFirstBlockWithTitle && (
        <h1 className="ImageText__title mb-8 w-full text-center font-grotesk-headline-news text-2xl md:text-3xl lg:text-left">
          {title}
        </h1>
      )}
      {!!title && !meta.isFirstBlockWithTitle && (
        <h2 className="ImageText__title mb-8 w-full text-center font-grotesk-headline-news text-2xl md:text-3xl lg:text-left">
          {title}
        </h2>
      )}
      <div
        className={cx('ImageText__image-outer-wrapper w-full lg:w-2/3 xl:w-3/4', {
          'pr-6 lg:pr-0': layout === 'imageLeft',
          'pl-6 lg:pl-0': layout === 'imageRight'
        })}
      >
        <div
          className="ImageText__image-inner-wrapper relative w-full"
          style={{
            paddingBottom: `${(imageHeight / imageWidth) * 100}%`
          }}
        >
          <Image
            className={cx('ImageText__image top-0 w-full', {
              'left-0': layout === 'imageLeft',
              'right-0': layout === 'imageRight'
            })}
            src={image.src}
            layout="fill"
            objectFit="contain"
            alt={image.alt}
          />
        </div>
      </div>
      <div className="ImageText__body-wrapper relative z-10 -mt-6 w-full lg:mt-0 lg:w-1/3 lg:pt-16 xl:w-1/4">
        <div
          className={cx('ImageText__body-padding', {
            'pl-6 lg:flex lg:justify-end lg:pl-0': layout === 'imageLeft',
            'pr-6 lg:pr-0': layout === 'imageRight'
          })}
        >
          <div className="ImageText__body bg-stone-200 py-6 px-8 text-sm lg:w-[120%] lg:shrink-0 font-grotesk-news leading-6">
            <PortableText content={body} />
          </div>
        </div>
        <div
          className={cx('ImageText__buttons-wrapper relative mt-8 flex justify-center flex-wrap', {
            'lg:!justify-end': layout === 'imageLeft',
            'lg:!justify-start': layout === 'imageRight'
          })}
        >
          {buttons?.primary?.label && buttons.primary.url && (
            <div className="ImageText__primary-button mb-4">
              <LinkButton
                variant="primary"
                label={buttons.primary.label}
                url={buttons.primary.url}
              />
            </div>
          )}
          {buttons?.secondary?.label && buttons.secondary.url && (
            <div
              className={cx('ImageText__secondary-button w-full flex justify-center', {
                'lg:!justify-end': layout === 'imageLeft',
                'lg:!justify-start': layout === 'imageRight'
              })}
            >
              <LinkButton
                className="text-xs"
                variant="link"
                label={buttons.secondary.label}
                url={buttons.secondary.url}
              />
            </div>
          )}
        </div>
      </div>
    </BlockWrapper>
  );
};

export default ImageText;
