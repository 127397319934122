import React, { FC } from 'react';
import cx from 'classnames';

import { LinkButton } from 'components';
import { LinkCard as TLinkCard } from 'types';

type Props = {
  link: TLinkCard;
  className?: string;
};

export const LinkCard: FC<Props> = ({ link, className = '' }) => {
  const { title, subtitle, button } = link;
  if (!title || !button) return null;

  return (
    <div
      className={cx(
        'LinkCard bg-stone-200 w-full flex flex-wrap p-6 justify-center lg:items-center',
        className
      )}
    >
      <div className="LinkCard__title-subtitle-wrapper text-center lg:text-left flex flex-wrap mb-4 lg:mb-0 lg:w-2/3">
        <div className="LinkCard__title w-full">
          <span className="text-sm font-grotesk-headline-news tracking-wide">{title}</span>
        </div>
        <div className="LinkCard__subtitle w-full">
          <span className="text-xs font-grotesk tracking-wide">{subtitle}</span>
        </div>
      </div>
      <div className="LinkCard__Button w-full lg:w-1/3 flex justify-center lg:flex-none">
        <LinkButton variant="primary" label={link.button.label} url={link.button.url} />
      </div>
    </div>
  );
};

export default LinkCard;
