import React, { FC, MouseEvent } from 'react';
import Link from 'next/link';
import cx from 'classnames';

import { linkIsRelative } from 'utils/linkIsRelative';

type Props = {
  label: string;
  url: string;
  className?: string;
  innerClassName?: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  variant:
    | 'primary'
    | 'secondary'
    | 'neutral'
    | 'primary-small'
    | 'secondary-small'
    | 'neutral-small'
    | 'link'
    | 'no-style';
};
// TO-DO: Change url to href
export const LinkButton: FC<Props> = ({
  label,
  url,
  variant,
  onClick = f => f,
  className = '',
  innerClassName = ''
}) => {
  if (!label || !url || !variant) return null;

  const baseClasses =
    'transition-all rounded-sm font-grotesk-headline tracking-wide whitespace-nowrap flex items-center justify-center w-fit';

  let variantClasses: string = '';
  if (variant === 'primary') {
    variantClasses = 'h-[45px] px-[1.6rem] text-xs bg-charcoal hover:opacity-90 text-white';
  }
  if (variant === 'secondary') {
    variantClasses = 'h-[45px] px-[1.6rem] text-xs bg-white hover:bg-charcoal hover:text-white';
  }
  if (variant === 'neutral') {
    variantClasses = 'h-[45px] px-[1.6rem] text-xs bg-stone-200 hover:bg-charcoal hover:text-white';
  }
  if (variant === 'primary-small') {
    variantClasses = 'py-2 px-4 text-[8px] bg-charcoal hover:opacity-90 text-white';
  }
  if (variant === 'secondary-small') {
    variantClasses = 'py-2 px-4 text-[8px] bg-white hover:bg-charcoal hover:text-white';
  }
  if (variant === 'neutral-small') {
    variantClasses = 'py-2 px-4 text-[8px] bg-stone-200 hover:bg-charcoal hover:text-white';
  }
  if (variant === 'link') {
    variantClasses = 'text-black underline hover:opacity-90 text-xs';
  }

  return (
    <div className={cx('LinkButton', className)}>
      {!linkIsRelative(url) ? (
        <a
          className={cx('LinkButton__relative-link', variantClasses, baseClasses, innerClassName)}
          href={url}
          target="_blank"
          rel="noreferrer noopener"
          onClick={onClick}
        >
          {label}
        </a>
      ) : (
        <Link href={url} passHref>
          <a
            className={cx('LinkButton__external-link', variantClasses, baseClasses, innerClassName)}
            onClick={onClick}
          >
            {label}
          </a>
        </Link>
      )}
    </div>
  );
};

export default LinkButton;
