import React, { FC, useCallback, useState } from 'react';
import cx from 'classnames';

import AnimateHeight from 'react-animate-height';
import Image from 'next/image';
import Link from 'next/link';

import iconPlus from '../../public/images/icon-plus.png';
import iconIncluded from '../../public/images/icon-included.svg';
import iconOptional from '../../public/images/icon-optional.svg';
import iconUnavailable from '../../public/images/icon-unavailable.svg';

import { Block, Image as TImage, PortableText as TPortableText } from 'types';
import { BlockWrapper, Button, LinkButton, PortableText } from 'components';

import { useCompareContext } from 'contexts/CompareContext';

export type TCompare = Block<
  'compare',
  {
    sectionTitle: string;
    title: string;
    col1Logo: TImage;
    col2Logo: TImage;
    items: ({
      _key: string;
      title: string;
      info: {
        images?: TImage[];
        description: TPortableText;
      };
    } & (
      | {
          layout: 'bigText' | 'smallText';
          col1Text: string;
          col2Text: string;
        }
      | {
          layout: 'icon';
          col1Icon: 'optional' | 'included' | 'unavailable';
          col2Icon: 'optional' | 'included' | 'unavailable';
        }
      | {
          layout: 'button';
          col1Button: { label: string; url: string };
          col2Button: { label: string; url: string };
        }
    ))[];
  }
>;

export const Compare: FC<TCompare> = ({ sectionTitle, title, items, col1Logo, col2Logo }) => {
  const [infoOverlay, setInfoOverlay] = useState<null | string>(null);
  const { active, setContext } = useCompareContext();

  const activeItemOverlay = items.find(item => item._key === infoOverlay);

  const toggleActive = useCallback(() => {
    const newActiveState = active === title ? '' : title;

    setContext({ active: newActiveState, setContext });
  }, [active, setContext, title]);

  const closeInfoOverlay = useCallback(() => setInfoOverlay(null), []);

  const isActive = active === title;

  const hasIconLayout = items.some(item => item.layout === 'icon');

  return (
    <BlockWrapper
      layout="full"
      className="Compare !mb-0 -mt-[1px] last-of-type:!mb-16 last-of-type:sm:!mb-24 md:container"
    >
      {!!sectionTitle && (
        <div className="Compare__section-title mb-10 container mx-auto">
          <span className="font-grotesk-headline-news text-2xl md:text-3xl lg:text-4xl">
            {sectionTitle}
          </span>
        </div>
      )}
      <div className="Compare__inner-wrapper border-y border-stone-200 transition-all">
        <div
          className={cx(
            'Compare__border border-stone-200 md:border-opacity-0 md:transition-opacity',
            { 'md:border-opacity-100': isActive }
          )}
        >
          <Button
            variant="no-style"
            className="relative flex w-full flex-wrap py-8 text-left"
            onClick={toggleActive}
          >
            <div
              className={cx('Compare__title relative w-full lg:w-1/2', {
                'mb-6 lg:mb-0': isActive
              })}
            >
              <span className="text-lg lg:text-xl pl-6">{title}</span>
            </div>
            <div
              className={cx(
                'Compare__column-logos-wrapper flex w-full items-center pr-10 transition-opacity lg:w-1/2',
                { 'opacity-0': !isActive }
              )}
            >
              <div className="Compare__column-1-logo w-full px-6 lg:px-0">
                <div className="relative hidden h-8 lg:block">
                  <Image
                    src={col1Logo.src}
                    alt={col1Logo.alt}
                    layout="fill"
                    objectFit="contain"
                    objectPosition="center"
                  />
                </div>
                <AnimateHeight height={isActive ? 'auto' : 0}>
                  <div className="relative h-8 lg:hidden">
                    <Image
                      src={col1Logo.src}
                      alt={col1Logo.alt}
                      layout="fill"
                      objectFit="contain"
                      objectPosition="left center"
                    />
                  </div>
                </AnimateHeight>
              </div>
              <div className="Compare__column-2-logo w-full">
                <div className="relative hidden h-8 lg:block">
                  <Image
                    src={col2Logo.src}
                    alt={col2Logo.alt}
                    layout="fill"
                    objectFit="contain"
                    objectPosition="center"
                  />
                </div>
                <AnimateHeight height={isActive ? 'auto' : 0}>
                  <div className="relative h-8 lg:hidden">
                    <Image
                      src={col2Logo.src}
                      alt={col2Logo.alt}
                      layout="fill"
                      objectFit="contain"
                      objectPosition="left center"
                    />
                  </div>
                </AnimateHeight>
              </div>
            </div>
            <div
              className={cx(
                'Compare__plus-icon-wrapper absolute top-8 right-8 h-6 w-6 transition-all',
                {
                  'rotate-45': isActive
                }
              )}
            >
              <Image src={iconPlus} alt="Collapse and expand icon" />
            </div>
          </Button>
          <div className="Compare__items w-full">
            <AnimateHeight height={isActive ? 'auto' : 0} duration={600} animateOpacity={true}>
              <div className="Compare__items__item-icon-key-wrapper">
                {items.map(item => (
                  <div
                    className="Compare__items__item flex flex-wrap justify-between py-8 odd:bg-stone-100 lg:pr-5"
                    key={`compare__item-${item._key}`}
                  >
                    <div className="Compare__items__item__title-info-button-wrapper flex items-center">
                      <div
                        className={cx('Compare__item-title mb-6 w-full lg:mb-0 lg:w-full pl-6', {
                          'pr-2': !!item.info
                        })}
                      >
                        <span className="font-grotesk-headline text-sm">{item.title}</span>
                      </div>
                      {!!item.info && (
                        <div className="Compare__items__item__info-button rounded-sm bg-stone-200 mb-6 lg:mb-0">
                          <Button variant="no-style" onClick={() => setInfoOverlay(item._key)}>
                            <div className="Compare__items__item__info-button__text px-3 rounded-sm transition-all hover:rounded-sm hover:bg-charcoal hover:text-white hover:opacity-90">
                              <span className="font-grotesk-headline text-[0.6rem] leading-6 uppercase">
                                info
                              </span>
                            </div>
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="Compare__items__item__columns w-full lg:w-1/2">
                      {(item.layout === 'smallText' || item.layout === 'bigText') && (
                        <div
                          className={cx(
                            'Compare__items__item__columns__text-wrapper flex w-full items-center justify-between',
                            { 'font-grotesk text-xs': item.layout === 'smallText' },
                            { 'font-grotesk-headline text-base': item.layout === 'bigText' }
                          )}
                        >
                          <div className="Compare__items__item__text-col1 w-1/2 pl-6 lg:pl-0 lg:text-center">
                            <span>{item.col1Text}</span>
                          </div>
                          <div className="Compare__items__item__text-col2 w-1/2 pr-2 lg:px-2 lg:text-center">
                            <span>{item.col2Text}</span>
                          </div>
                        </div>
                      )}
                      {item.layout === 'icon' && (
                        <div className="Compare__icon-wrapper flex w-full">
                          <div className="Compare__items__item__icon-col1 w-1/2 lg:flex lg:justify-center">
                            {item.col1Icon === 'included' && (
                              <div className="Compare__items__item__icon-col1__included-icon ml-6 lg:ml-0">
                                <Image src={iconIncluded} alt="Included icon" />
                              </div>
                            )}
                            {item.col1Icon === 'optional' && (
                              <div className="Compare__items__item__icon-col1__optional-icon ml-6 lg:ml-0">
                                <Image src={iconOptional} alt="Optional icon" />
                              </div>
                            )}
                            {item.col1Icon === 'unavailable' && (
                              <div className="Compare__items__item__icon-col1__unavailable-icon ml-6 lg:ml-0">
                                <Image src={iconUnavailable} alt="Unavailable icon" />
                              </div>
                            )}
                          </div>
                          <div className="Compare__items__item__icon-col2 w-1/2 lg:flex lg:justify-center">
                            {item.col2Icon === 'included' && (
                              <div className="Compare__items__item__icon-col2__included-icon">
                                <Image src={iconIncluded} alt="Included icon" />
                              </div>
                            )}
                            {item.col2Icon === 'optional' && (
                              <div className="Compare__items__item__icon-col2__optional-icon">
                                <Image src={iconOptional} alt="Optional icon" />
                              </div>
                            )}
                            {item.col2Icon === 'unavailable' && (
                              <div className="Compare__items__item__icon-col2__unavailable-icon">
                                <Image src={iconUnavailable} alt="Unavailable icon" />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {item.layout === 'button' && (
                        <div className="Compare__items__item__button-wrapper flex items-center w-full">
                          <div className="Compare__items__item__button-col1 lg:flex lg:justify-center mr-[4.8rem] lg:mr-0 lg:w-1/2">
                            <LinkButton
                              variant="primary"
                              label={item.col1Button.label}
                              url={item.col1Button.url}
                            />
                          </div>
                          <div className="Compare__items__item__button-col2 lg:flex lg:justify-center lg:w-1/2">
                            <LinkButton
                              variant="primary"
                              label={item.col2Button.label}
                              url={item.col2Button.url}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {!!hasIconLayout && (
                  <div className="Compare__items__item__icon-key w-full border-t-[1px] border-stone-200 p-4">
                    <div className="Compare__items__item__icon-key__inner-wrapper w-full flex items-center flex-wrap">
                      <div className="Compare__items__item__idon-key__unavailable flex items-center py-1 md:py-0 pr-4 md:pr-8">
                        <div className="Compare__items__item__icon-key__unavailable-icon relative w-5 h-5">
                          <Image src={iconUnavailable} layout="fill" alt="Unavailable icon" />
                        </div>
                        <div className="Compare__items__item__icon-key__unavailable-text pl-2">
                          <span className="text-xs">Unavailable</span>
                        </div>
                      </div>
                      <div className="Compare__items__item__idon-key__optional flex items-center py-1 md:py-0 pr-4 md:pr-8">
                        <div className="Compare__items__item__icon-key__optional-icon relative w-5 h-5">
                          <Image src={iconOptional} layout="fill" alt="Optional icon" />
                        </div>
                        <div className="Compare__items__item__icon-key__optional-text pl-2">
                          <span className="text-xs">Optional</span>
                        </div>
                      </div>
                      <div className="Compare__items__item__idon-key__included flex items-center py-1 md:py-0 pr-4 md:pr-8">
                        <div className="Compare__items__item__icon-key__included-icon relative w-5 h-5">
                          <Image src={iconIncluded} layout="fill" alt="Included icon" />
                        </div>
                        <div className="Compare__items__item__icon-key__included-text pl-2">
                          <span className="text-xs">Included</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </AnimateHeight>
            {!!activeItemOverlay && (
              <div className="Compare__items__info-overlay fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-50">
                <div
                  className="Compare__items__info-overlay__background absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-20 z-0"
                  onClick={closeInfoOverlay}
                />
                <div className="Compare__items__info-overlay__inner-wrapper inline-block bg-white p-8 w-full md:w-5/6 lg:w-2/3 2xl:w-1/2 rounded-sm shadow-lg z-50">
                  <div className="Compare__items__info-overlay__name mb-8 md:mb-10">
                    <span className="text-fire font-grotesk-sub-headline-news text-[15px] md:text-[22px] tracking-wide">
                      {activeItemOverlay.title}
                    </span>
                  </div>
                  {!!activeItemOverlay.info.images && (
                    <div className="Compare__items__info-overlay__images w-full flex flex-nowrap items-center justify-between mb-4">
                      {activeItemOverlay.info.images?.map(image => (
                        <div
                          className="Compare__items__info-overlay__image-wrapper w-[48%]"
                          key={image._key}
                        >
                          <div
                            className="Compare__items__info-overalay__image relative"
                            style={{
                              paddingBottom: `${
                                ((image.metadata?.dimensions.height || 0) /
                                  (image.metadata?.dimensions.width || 0)) *
                                100
                              }%`
                            }}
                          >
                            <Image src={image.src} layout="fill" alt={image.alt} />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="Compare__items__info-overlay__description text-sm mb-8 leading-relaxed">
                    <PortableText content={activeItemOverlay.info.description} />
                  </div>
                  <div className="Compare__info-overlay__back-button">
                    <Button onClick={closeInfoOverlay} variant="primary">
                      <span>Close</span>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default Compare;
