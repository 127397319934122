import React, { FC, useState, useCallback } from 'react';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import cx from 'classnames';

type Props = NextImageProps & {
  hideLoadingPlaceholder?: boolean;
};

const Image: FC<Props> = props => {
  const [isLoading, setIsLoading] = useState(true);
  const onLoadingComplete = useCallback(() => {
    setIsLoading(false);
    if (props.onLoadingComplete)
      props.onLoadingComplete({
        naturalWidth: !!props.width ? (props.width as number) : 0,
        naturalHeight: !!props.height ? (props.height as number) : 0
      });
  }, [props]);

  if (!props.src) return null;

  const _props = {
    ...props
  };

  delete _props['hideLoadingPlaceholder'];

  return (
    <>
      <div
        className={cx(
          'Image__placeholder absolute top-0 right-0 bottom-0 left-0 w-full h-full bg-stone-100 transition-opacity',
          { 'opacity-0': !isLoading || props.hideLoadingPlaceholder }
        )}
      />
      <NextImage
        {..._props}
        className={cx('Image', 'transition-opacity shadow-2xl shadow-black', props.className, {
          'opacity-0': isLoading
        })}
        onLoadingComplete={onLoadingComplete}
      />
    </>
  );
};

export default Image;
