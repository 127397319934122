import React, { FC, useCallback, useEffect } from 'react';
import { animateScroll } from 'react-scroll';
import cx from 'classnames';

import { Block, Image as TImage } from 'types';
import { BlockWrapper, Button, Image } from 'components';
import { useNavContext } from 'contexts/NavContext';

import iconDownArrowWhite from '../../public/images/v2-icon-down-white.svg';
import iconDownArrowBlack from '../../public/images/v2-icon-down-charcoal.svg';

export type THero = Block<
  'hero',
  {
    title?: string;
    theme: 'white' | 'black';
    mobileImage: TImage;
    desktopImage: TImage;
    nudgeButton?: string;
  }
>;

// TO-DO: Add the ability to "crop and hotspot" from Sanity
export const Hero: FC<THero> = ({ nudgeButton, theme, mobileImage, desktopImage }) => {
  const nudgeIcon = theme === 'black' ? iconDownArrowBlack : iconDownArrowWhite;
  const navContext = useNavContext();

  const onNudgeButtonClick = useCallback(() => {
    animateScroll.scrollMore(300);
  }, []);

  useEffect(() => {
    if (!!theme && theme !== navContext.theme) navContext.setContext({ ...navContext, theme });
  }, [navContext, theme]);

  return (
    <BlockWrapper layout="full" className="Hero relative">
      <div className="Hero__mobile-image-wrapper relative h-[600px] lg:hidden">
        <Image
          src={mobileImage.src}
          layout="fill"
          objectFit="cover"
          alt={mobileImage.alt}
          priority={true}
        />
      </div>
      <div className="Hero__desktop-image-wrapper relative hidden h-[600px] lg:block">
        <Image
          src={desktopImage.src}
          layout="fill"
          objectFit="cover"
          alt={desktopImage.alt}
          quality={90}
          priority={true}
        />
      </div>
      {!!nudgeButton && (
        <div className="Hero__nudge-button absolute bottom-8 flex w-full justify-center">
          <Button
            variant="no-style"
            onClick={onNudgeButtonClick}
            className="flex h-[42px] flex-col items-center justify-between transition-all hover:h-[52px] hover:translate-y-2"
          >
            <span
              className={cx('font-grotesk-sub-headline-news text-sm tracking-widest', {
                'text-white': theme === 'white',
                'text-charcoal': theme === 'black'
              })}
            >
              {nudgeButton}
            </span>
            <div className="w-5">
              <Image src={nudgeIcon} alt="Scroll page down icon" />
            </div>
          </Button>
        </div>
      )}
    </BlockWrapper>
  );
};

export default Hero;
