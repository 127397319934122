import React, { FC, useState, useCallback } from 'react';

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import STRIPE_STYLES from 'clients/Stripe/styles';
import EscapodApi from 'clients/Escapod';

import { TextField, Button, InvalidPage } from 'components';

const PaymentForm: FC<{ id: string; orderExists: boolean }> = ({ id, orderExists }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [order, setOrder] = useState<any | null>(null);

  const stripe = useStripe();
  const elements = useElements();

  const verify = useCallback(() => {
    if (!email) setError('Please enter a valid email.');

    setIsVerifying(true);

    EscapodApi.orders
      .verify(id, email)
      .then((order: any) => setOrder(order))
      .catch((err: any) => setError(err?.message))
      .finally(() => setIsVerifying(false));
  }, [id, email, setOrder, setError]);

  const submitPayment = useCallback(() => {
    if (!stripe || !elements) return null;

    const card = elements?.getElement(CardElement);
    if (!card) return null;

    const createToken = async () => {
      const { token } = await stripe.createToken(card);

      if (!token) return null;

      EscapodApi.orders.payments
        .create(id, token.id)
        .then(() => {
          setIsSuccess(true); // TO-DO: Mount any relevant data to state from response
        })
        .catch() // TO-DO: Add Error State
        .finally(); // TO-DO: Set loading state to false
    };

    createToken();
  }, [stripe, elements, id]);

  return (
    <div className="PaymentForm">
      {isSuccess ? (
        <div>Success</div>
      ) : (
        <>
          {!order ? (
            <div className="min-h-100 py-48 container">
              {orderExists ? (
                <form>
                  <p>Verify the email address associated with the order to continue to payment.</p>
                  <div className="mt-6">
                    <TextField
                      name="PaymentEmail"
                      ariaLabel="Email Address"
                      label="Email Address"
                      placeholder="example@escapod.us"
                      variant="primary"
                      type="text"
                      value={email}
                      onChange={email => setEmail(email as string)}
                      error={error}
                      showError={!!error}
                    />
                    <Button
                      className="mt-6"
                      variant="primary"
                      onClick={verify}
                      disabled={isVerifying}
                    >
                      {isVerifying ? 'Verifying...' : 'Verify'}
                    </Button>
                  </div>
                </form>
              ) : (
                <InvalidPage
                  title="This payment link is not valid"
                  description="We could not find an active invoice for this order number. Please reach out to sales@escapod.us if you continue to experience issues."
                  leftButton={{ label: 'Return Home', url: '/' }}
                  rightButton={{ label: 'Contact Sales', url: '/contact-us' }}
                />
              )}
            </div>
          ) : (
            <div className="min-h-100 py-48 container">
              <div>Order: {order.name}</div>
              <div>
                <strong>Pay:</strong>
                <CardElement options={STRIPE_STYLES} />
                <Button variant="primary" onClick={submitPayment}>
                  Submit
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentForm;
