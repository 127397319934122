import React, { FC } from 'react';
import Link from 'next/link';
import cx from 'classnames';

import { Image, LinkButton } from 'components';
import { Button as TButton } from 'types';

import logoBlack from '../../public/images/v2-logo.svg';

type Props = {
  title: string;
  description: string;
  leftButton: TButton;
  rightButton?: TButton;
  className?: string;
};

export const InvalidPage: FC<Props> = ({
  title,
  description,
  leftButton,
  rightButton,
  className = ''
}) => {
  return (
    <div className={cx(`InvalidPage--${title}`, className)}>
      <div className="InvalidPage__logo absolute top-0 left-0 ml-6 md:ml-12 lg:ml-36 mt-12 lg:mt-24">
        <Link href="/">
          <a>
            <Image width="200" height="35" src={logoBlack} alt="Escapod Logo Black" />
          </a>
        </Link>
      </div>
      <div className="InvalidPage__inner-container fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-center container">
        <h1 className="InvalidPage__title w-full text-center font-grotesk-headline-news text-2xl md:text-3xl mb-6">
          {title}
        </h1>
        <div className="InvalidPage__description">
          <p className="max-w-prose mx-auto tracking-wide text-xl mb-12">{description}</p>
        </div>
        <div className="InvalidPage__buttons flex justify-center items-center">
          <div className="InvalidPage__leftButton">
            <LinkButton url={leftButton.url} label={leftButton.label} variant="primary" />
          </div>
          {!!rightButton && (
            <div className="InvalidPage__rightButton ml-4 lg:ml-8">
              <LinkButton url={rightButton.url} label={rightButton.label} variant="primary" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvalidPage;
