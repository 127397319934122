import React, { FC, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/nextjs';

import { useRouter } from 'next/router';
import EscapodAPI from 'clients/Escapod';
import * as Gtag from 'clients/Gtag';
import emailIsValid from 'utils/emailIsValid';

import { FormWrapper, TextField, Button, Image } from 'components';
import { useNewsletterContext } from 'contexts/NewsletterContext';

import iconClose from '../public/images/v2-icon-close.svg';

const NEWSLETTER_COOKIE = '__ESCAPOD_NEWSLETTER_POPUP';

export const NewsletterPopup: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const newsletterContext = useNewsletterContext();

  const [error, setError] = useState<{ [key: string]: string }>({});

  const page = useRouter();

  const closePopup = useCallback(() => {
    Cookies.set(NEWSLETTER_COOKIE, 'true', { expires: 30 });
    setIsActive(false);
  }, [setIsActive]);

  const submit = useCallback(() => {
    setError({});

    if (!email || !emailIsValid(email)) {
      return setError({
        email: 'Please provide a valid email address.'
      });
    }

    Sentry.setUser({ email });

    setIsSending(true);

    EscapodAPI.klaviyo
      .subscribe({ email })
      .then(res => {
        if (res.status === 201) {
          Gtag.event('conversion', {
            send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
            transaction_id: window.btoa(email),
            event_callback: () => {}
          });
          setError({ form: 'Thank you for your submission!' });
          setTimeout(() => {
            closePopup();
            setError({});
            setEmail('');
            setIsSending(false);
          }, 3000);
        }
      })
      .catch(err => {
        setIsSending(false);
        if (err.response.status === 400) {
          if (err.response.data.message === 'email invalid') {
            return setError({ email: 'Please enter a valid email address.' });
          }
          if (err.response.data.message === 'listId invalid') {
            return setError({ form: 'Something went wrong. Please try again later!' });
            // TO-DO: Add Sentry to notify developers of a listId malfunction
          }
          if (err.response.data.message === 'member exists') {
            return setError({
              form: "You've already subscribed with that email address! Try a different one."
            });
          }
        }
        return setError({ form: 'Something went wrong. Please try again later!' });
        // TO-DO: Add Sentry to notify developers of an unknown malfunction
      });
  }, [email, page.asPath, newsletterContext.campaign, closePopup]);

  useEffect(() => {
    setTimeout(() => {
      if (Cookies.get(NEWSLETTER_COOKIE) !== 'true') setIsActive(true);
    }, 5000);
  }, []);

  return (
    <div
      className={cx(
        'NewsletterPopup fixed bottom-0 left-0 right-0 w-full z-50 transition-transform rounded-sm bg-stone-200 shadow-above',
        {
          'translate-y-full': !isActive
        }
      )}
    >
      <div className="NewsletterPopup__inner-wrapper container relative flex flex-wrap justify-center text-center pt-12 pb-16">
        <div className="NewsletterPopup__title-close-button-wrapper mb-5 flex items-center justify-between w-full">
          <div className="NewsletterPopup__title">
            <span className="md:whitespace-nowrap font-grotesk-headline-news text-2xl md:text-3xl tracking-wide text-charcoal">
              Stay in Touch
            </span>
          </div>
          <div className="NewsletterPopup__close-button mt-1">
            <Button variant="link" onClick={closePopup}>
              <div className="relative h-[18px] w-[18px]">
                <Image src={iconClose} layout="fill" alt="Newsletter popup close icon" />
              </div>
            </Button>
          </div>
        </div>
        <div className="NewsletterPopup__message-form-wrapper flex w-full items-center flex-wrap md:flex-nowrap">
          <div className="NewsletterPopup__message text-left mb-4 md:w-1/2 md:pr-10 md:mb-0">
            <span className="text-sm">
              We’re always dreaming up new ways to get you outdoors. Sign up and we’ll share with
              you what we’re working on before anyone else. No spam here, just rugged, outdoorsy
              goodness.
            </span>
          </div>
          <FormWrapper
            className="NewsletterPopup__form-wrapper w-full md:w-1/2 flex flex-nowrap justify-between"
            id="NewsletterPopup-Subscribe"
            name="NewsletterPopup-Subscribe"
          >
            <div className="NewsletterPopup__email-input w-2/3 mr-4 mb-4 md:mb-0 flex-grow">
              <TextField
                className="whitespace-nowrap"
                id="Newsletter-popup-form-email"
                name="email"
                placeholder="Email Address"
                ariaLabel="Email Address"
                value={email}
                error={error.email}
                showError={!!error.email}
                onChange={value => setEmail(value as string)}
              />
            </div>
            <div className="NewsletterPopup__signup-button">
              <Button variant="primary" type="button" disabled={isSending} onClick={submit}>
                {isSending ? 'Sending' : 'Submit'}
              </Button>
            </div>
          </FormWrapper>
        </div>
        {error.form && (
          <div className="NewsletterPopup__form-error absolute bottom-20 md:top-0 md:right-14">
            <span className="text-center text-sm text-fire">{error.form}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsletterPopup;
