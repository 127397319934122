import React, { FC, useState, useEffect, useMemo, useCallback, FormEvent, MouseEvent } from 'react';
import cx from 'classnames';
import { animateScroll } from 'react-scroll';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import AnimateHeight from 'react-animate-height';
import Cookies from 'js-cookie';

import Fbq from 'clients/Fbq';

import iconCheck from '../../public/images/icon-check.png';
import iconPlus from '../../public/images/icon-plus.png';

import { Button, BlockWrapper, PortableText, Image, Content } from 'components';
import { useBuilderContext, useBuilderMutate, STORAGE_KEY } from 'contexts/BuilderContext';
import { useNewsletterContext } from 'contexts/NewsletterContext';
import formatMoney from 'utils/formatMoney';
import * as Gtag from 'clients/Gtag';

import { ProductLineModification, ProductLineFeature, CustomerInterestLevel } from 'escapod';
import { Block } from 'types';
import { TImageText } from './ImageText';
import { TProductCards } from './ProductCards';
import fireworks from 'utils/fireworks';

export type TBuilder = Block<
  'builder',
  {
    title: string;
    trailer: string;
    confirmationContent: (TImageText | TProductCards)[];
    submissionDisabled: boolean;
    collapseBaseFeatures: boolean;
    collapseModifications: boolean;
  }
>;

type BuilderCustomer = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  preferredContactMethod: string;
  message: string;
  newsletterOptIn: boolean;
};

// TO-DO: Fix this in long term with proper overlays
const PromoOverrideStyles = () => (
  <style
    dangerouslySetInnerHTML={{
      __html: `
        .Content > .Block:not(.Builder) { display: none !important }
        .Content { padding-top: 8rem }
        
        @media (min-width: 1280px) {
          .Content { padding-top: 12rem }
        }
      `
    }}
  />
);

export const Builder: FC<TBuilder> = ({
  title,
  trailer,
  confirmationContent,
  submissionDisabled,
  collapseBaseFeatures,
  collapseModifications,
  meta
}) => {
  const [infoOverlay, setInfoOverlay] = useState<ProductLineModification | null>(null);
  const [baseFeatureOverlay, setBaseFeatureOverlay] = useState<ProductLineFeature | null>(null);
  const [customerMessageOverlayIsActive, setCustomerMessageOverlayIsActive] =
    useState<boolean>(false);
  const [infoOverlayIntent, setInfoOverlayIntent] = useState<'add' | 'remove'>('add');
  const [stage, setStage] = useState<
    'builder' | 'interest' | 'customer' | 'payment' | 'confirmation'
  >('builder');
  const [confirmationStep, setConfirmationStep] = useState<'confetti' | 'nextSteps'>('confetti');
  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);

  const [baseFeaturesCollapsed, setBaseFeaturesCollapsed] = useState(collapseBaseFeatures || false);
  const [modificationsCollapsed, setModificationsCollapsed] = useState(
    collapseModifications || false
  );

  const [interest, setInterest] = useState<CustomerInterestLevel | null>(null);
  const [customer, setCustomer] = useState<BuilderCustomer>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    preferredContactMethod: '',
    message: '',
    newsletterOptIn: true
  });
  const [customerErrors, setCustomerErrors] = useState<
    Partial<BuilderCustomer> & { form?: string }
  >({});

  const [nameOnCard, setNameOnCard] = useState<string>('');
  const [paymentErrors, setPaymentErrors] = useState<{ form: string }>({ form: '' });

  const { selectedTrailer, selectedVariantOptions, trailers, total, modifications } =
    useBuilderContext();
  const newsletterContext = useNewsletterContext();
  const modificationsBySku = useMemo(() => modifications.map(mod => mod.sku), [modifications]);
  const mutate = useBuilderMutate();

  const closeBaseFeatureOverlay = useCallback(() => setBaseFeatureOverlay(null), []);
  const closeCustomerMessageOverlay = useCallback(
    () => setCustomerMessageOverlayIsActive(false),
    []
  );

  const router = useRouter();

  useEffect(() => {
    if (mutate && !!trailers.length && (!selectedTrailer || trailer !== selectedTrailer._id)) {
      mutate.selectTrailer(trailer);
    }
  }, [trailer, trailers, mutate, selectedTrailer, modifications]);

  const add = useCallback(
    (mod: ProductLineModification) => {
      if (!mutate) return;

      if (
        mutate.unmetDependenciesForModification(mod).length ||
        mutate.conflictsForModification(mod).length
      ) {
        setInfoOverlayIntent('add');
        return setInfoOverlay(mod);
      }

      return mutate.addModification(mod);
    },
    [mutate]
  );

  const addFromInfoOverlay = useCallback(() => {
    if (!mutate || !infoOverlay) return;

    const modsToAdd = [infoOverlay, ...mutate.unmetDependenciesForModification(infoOverlay)];
    const modsToRemove = mutate.conflictsForModification(infoOverlay);

    modsToRemove.forEach(mutate.removeModification);
    modsToAdd.forEach(mutate.addModification);
    setInfoOverlay(null);
  }, [mutate, infoOverlay]);

  const removeFromInfoOverlay = useCallback(() => {
    if (!mutate || !infoOverlay) return;

    const modsToRemove = [infoOverlay, ...mutate.selectedDependentsForModification(infoOverlay)];

    modsToRemove.forEach(mutate.removeModification);
    setInfoOverlay(null);
  }, [mutate, infoOverlay]);

  const remove = useCallback(
    (mod: ProductLineModification) => {
      if (!mutate) return;

      if (mutate.selectedDependentsForModification(mod).length) {
        setInfoOverlayIntent('remove');
        return setInfoOverlay(mod);
      }

      return mutate.removeModification(mod);
    },
    [mutate]
  );

  const closeInfoOverlay = useCallback(() => setInfoOverlay(null), []);

  const image = selectedTrailer?.images?.find(image => {
    const unmatchedVariants = Object.values(selectedVariantOptions).reduce(
      (unmatchedVariants, option) => {
        const firstMatch = unmatchedVariants.findIndex(unmatched => option === unmatched);

        return unmatchedVariants.filter((_, i) => i !== firstMatch);
      },
      image.values
    );

    return unmatchedVariants.length === 0;
  });

  const _continue = useCallback(() => {
    router.push('/order-now/topo-2/info'); // TO-DO: Add 'slug' field to Product in PODS so we can use it here to dynamically route the product
  }, [router]);

  if (!selectedTrailer) return null;

  const variantImageWidth = image?.metadata?.dimensions.width;
  const variantImageHeight = image?.metadata?.dimensions.height;

  return (
    <BlockWrapper className="Builder">
      <div className="Builder__title mb-12 mt-0 md:mt-16 lg:mt-0 text-center">
        <div className="Builder__title__title-price mb-2">
          {meta.isFirstBlockWithTitle ? (
            <h1 className="grotesk-headline font-grotesk-headline text-2xl lg:text-3xl tracking-wide">
              {title} - ${formatMoney(selectedTrailer.price)}
            </h1>
          ) : (
            <h2 className="grotesk-headline font-grotesk-headline text-2xl lg:text-3xl tracking-wide">
              {title} - ${formatMoney(selectedTrailer.price)}
            </h2>
          )}
        </div>
        {!submissionDisabled && (
          <div className="Builder__title__estimated-delivery">
            <span className="text-xs text-stone-400 lg:text-sm">
              Current Estimated Delivery: {selectedTrailer.leadTime}
            </span>
          </div>
        )}
      </div>
      <div className="Builder__color-picker mb-16">
        <div
          className="Builder__color-picker__image mb-10 pb-8 relative"
          style={{
            paddingBottom: `${((variantImageHeight || 0) / (variantImageWidth || 0)) * 100}%`
          }}
        >
          <Image
            src={image?.src || ''}
            layout="fill"
            hideLoadingPlaceholder={true}
            quality={100}
            alt={`${selectedTrailer.name} in ${Object.values(selectedVariantOptions).join(', ')}`}
          />
          {selectedTrailer.images.map(image => (
            <div
              key={`Builder__image-variant-preloader__${image.src}`}
              className="hidden absolute w-full h-full top-0 left-0 right-0 bottom-0"
            >
              <Image
                src={image.src}
                layout="fill"
                priority={true}
                quality={100}
                alt={`${selectedTrailer.name} in ${image.values.join(' / ')}`}
              />
            </div>
          ))}
        </div>
        <div className="Builder__color-picker__variant-groups md:flex md:justify-between w-full">
          {selectedTrailer.variantGroups.map(group => (
            <div
              className={cx(
                'Builder__color-picker__variant-groups__variant-group flex items-center justify-between w-full md:first-of-type:pr-10 lg:first-of-type:pr-16',
                {
                  'flex-wrap sm:flex-nowrap': group.variants.length > 4,
                  '!pr-0 w-[70%] md:w-[60%] xl:w-[47%] 2xl:w-[40%]':
                    selectedTrailer.name === 'Original TOPO'
                }
              )}
              key={`color-picker__variant-group__${group._key}`}
            >
              <div
                className={cx(
                  'Builder__color-picker__variant-groups__variant-group__names flex flex-wrap',
                  {
                    'mb-4 sm:mb-0': group.variants.length > 4
                  }
                )}
              >
                <span className="w-full font-grotesk-sub-headline text-base text-fire lg:text-xl xl:text-2xl tracking-wide mb-1">
                  {group.name}
                </span>
                <span className="font-grotesk-news text-sm tracking-wide">
                  {selectedVariantOptions?.[group.name]}
                </span>
              </div>
              <div className="Builder__color-picker__variant-groups__variant-group__icons flex">
                {group.variants.map(variant => (
                  <div
                    className="Builder__color-picker__variant-groups__variant-group__icons__icon-wrapper"
                    key={`color-picker__icon__${variant._key}`}
                  >
                    <Button
                      className={cx(
                        'rounded-circle border-[1px] border-stone-200 border-opacity-0 p-[6px] lg:p-2',
                        {
                          'border-opacity-100':
                            selectedVariantOptions?.[group.name] === variant.name
                        }
                      )}
                      variant={'no-style'}
                      onClick={() => mutate?.selectVariantOptions({ [group.name]: variant.name })}
                    >
                      <div className="Builder__color-picker__variant-groups__variant-group__icons__icon-image w-7 h-7 lg:w-10 lg:h-10 relative rounded-circle overflow-hidden">
                        <Image
                          hideLoadingPlaceholder={true}
                          src={variant.icon}
                          alt={`${variant.name} color selector`}
                          layout="fill"
                        />
                      </div>
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedTrailer.featureGroups.map(group => (
        <div
          className={cx('Builder__base-features', {
            'mb-20': !collapseBaseFeatures,
            '': collapseBaseFeatures
          })}
          key={`Builder__base-features__group-${group._key}`}
        >
          <Button
            variant="no-style"
            className={cx('Builder__base-features__title mb-6 relative w-full text-left', {
              'border-b-[1px] pb-6': collapseBaseFeatures,
              'cursor-default': !collapseBaseFeatures,
              'border-b-stone-200': baseFeaturesCollapsed,
              'border-b-transparent': !baseFeaturesCollapsed
            })}
            onClick={
              collapseBaseFeatures
                ? () => setBaseFeaturesCollapsed(!baseFeaturesCollapsed)
                : () => {}
            }
          >
            <span className="font-grotesk-sub-headline text-fire lg:text-xl xl:text-2xl tracking-wide">
              {group.name}
            </span>
            {collapseBaseFeatures && (
              <div
                className={cx('absolute right-0 top-0 h-7 w-7 origin-center transition-transform', {
                  'rotate-45': !baseFeaturesCollapsed
                })}
              >
                <Image src={iconPlus} alt="Collapse and expand icon" />
              </div>
            )}
          </Button>
          <AnimateHeight height={baseFeaturesCollapsed ? 0 : 'auto'}>
            <div className="Builder__base-features md:grid md:grid-cols-2 md:gap-x-12">
              {group.features.map(feature => (
                <div
                  className="Builder__base-features__feature py-3 flex items-center justify-between border-b-[1px] border-b-stone-200"
                  key={`Builder__base-features__feature-${feature._key}`}
                >
                  <div className="Buidler__base-features__feature__name-info-button-wrapper items-center flex justify-between w-[80%] sm:w-[85%] 2xl:w-[88%] pr-2 md:pr-4">
                    <div className="Builder__base-features__feature__title pr-2 md:pr-4 leading-tight">
                      <span className="font-grotesk-news text-xs lg:text-sm tracking-wide">
                        {feature.name}
                      </span>
                    </div>
                    {(!!feature.description || feature.images) && (
                      <div className="Builder__base-feature__feature__info-button rounded-sm">
                        <Button variant="no-style" onClick={() => setBaseFeatureOverlay(feature)}>
                          <div className="Builder__base-feature__feature__info-button__text px-3 rounded-sm transition-all hover:rounded-sm bg-stone-200 hover:bg-charcoal hover:text-white hover:opacity-90">
                            <span className="font-grotesk-headline text-[0.6rem] leading-6 uppercase">
                              info
                            </span>
                          </div>
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="Builder__base-features__feature__icon flex h-[40px] w-[70px] px-4 justify-center rounded-sm bg-fire py-[0.85rem]">
                    <div className="flex w-4 h-3 relative">
                      <Image
                        hideLoadingPlaceholder={true}
                        src={iconCheck}
                        alt="Included check mark icon"
                        layout="fill"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </AnimateHeight>
        </div>
      ))}
      {collapseModifications && (
        <Button
          variant="no-style"
          className={cx(
            'Builder__base-features__title pb-6 mb-12 relative flex items-center w-full border-b-[1px]',
            {
              'border-b-stone-200': modificationsCollapsed,
              'border-b-transparent': !modificationsCollapsed
            }
          )}
          onClick={() => setModificationsCollapsed(!modificationsCollapsed)}
        >
          <span className="font-grotesk-sub-headline text-fire lg:text-xl xl:text-2xl">
            Optional Add-Ons
          </span>
          <div
            className={cx('absolute right-0 top-0 h-7 w-7 origin-center transition-transform', {
              'rotate-45': !modificationsCollapsed
            })}
          >
            <Image src={iconPlus} alt="Collapse and expand icon" />
          </div>
        </Button>
      )}
      <AnimateHeight height={modificationsCollapsed ? 0 : 'auto'}>
        <div className="Builder__add-ons">
          {selectedTrailer.modificationGroups.map(group => (
            <div
              className="Builder__add-ons__group-inner-wrapper mb-20"
              key={`Builder__add-ons__group-${group._key}`}
            >
              <div className="Builder__add-ons__group__title mb-6">
                <span
                  className={cx('tracking-wide', {
                    'font-grotesk-sub-headline text-fire lg:text-xl xl:text-2xl':
                      !collapseModifications,
                    'font-grotesk-headline transition-color uppercase text-charcoal':
                      collapseModifications
                  })}
                >
                  {group.name}
                </span>
              </div>
              <div className="Builder__add-ons__group__add-ons md:grid md:grid-cols-2 md:gap-x-12">
                {group.modifications.map(mod => {
                  const isSelected = modificationsBySku.includes(mod.sku);
                  return (
                    <div
                      className="Builder__add-ons__add-on mb-3 flex items-center justify-between border-b-[1px] border-stone-200 md:flex-wrap"
                      key={`Builder__add-on__mod-${mod._key}`}
                    >
                      <div className="Builder__add-ons__add-on__name-info-button mb-3 flex w-[59%] items-center pr-2">
                        <div className="Builder__add-ons__add-on__name pr-2 leading-tight">
                          <span className="font-grotesk-news text-xs lg:text-sm tracking-wide">
                            {mod.name}
                          </span>
                        </div>
                        {(mod.description || mod.images?.length) && (
                          <div className="Builder__add-ons__add-on__info-button rounded-sm bg-stone-200">
                            <Button variant="no-style" onClick={() => setInfoOverlay(mod)}>
                              <div className="Builder__add-ons__info-button__text px-3 transition-all hover:rounded-sm hover:bg-charcoal hover:text-white hover:opacity-90">
                                <span className="font-grotesk-headline text-[0.6rem] leading-6 uppercase">
                                  info
                                </span>
                              </div>
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="Builder__add-ons__add-on__price-selector-wrapper mb-3 flex items-center">
                        <div className="Builder__add-ons__add-on__price pr-2">
                          <span className="text-xs lg:text-sm tracking-wide">${mod.price}</span>
                        </div>
                        <div className="Builder__add-ons__add-on__selector pl-1">
                          <Button
                            className={cx('h-[40px] w-[70px] !px-4 text-center', {
                              'bg-fire hover:bg-charcoal': isSelected
                            })}
                            variant={isSelected ? 'primary' : 'neutral'}
                            onClick={isSelected ? () => remove(mod) : () => add(mod)}
                          >
                            {isSelected ? (
                              <div className="Builder__add-ons__add-on__selector__icon w-full flex items-center justify-center">
                                <div className="Builder__add-ons__add-on__selector__selected-icon inline-block w-4 h-3 relative">
                                  <Image
                                    hideLoadingPlaceholder={true}
                                    src={iconCheck}
                                    alt={`${mod.name} accessory has been selected icon`}
                                    layout="fill"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="Builder__add-ons__add-on__selector__button-text -my-[2px]">
                                <span>Add</span>
                              </div>
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </AnimateHeight>
      <div className="Builder__total">
        <div className="Builder__total__selection flex flex-wrap mb-16 md:mb-24 items-start">
          <div className="Builder__total__selection-text w-full md:w-1/2 text-center md:text-left mb-12 md:mb-0">
            <span className="font-grotesk-headline text-xl uppercase md:text-3xl tracking-wide">
              Total -{' '}
            </span>
            <span className="font-grotesk-headline text-xl text-fire md:text-3xl tracking-wide">
              ${formatMoney(total)}
            </span>

            <div className="Builder__total__legal-info w-full text-center md:text-left mt-4 px-4 md:px-0">
              <span className="font-grotesk text-xs text-stone-400 md:text-sm">
                Price listed above is before taxes and fees.
              </span>
            </div>
          </div>
          <div className="Builder__total__selection-continue w-full flex flex-wrap items-center justify-center md:justify-end md:w-1/2">
            <span className="tracking-wide font-grotesk-news text-xs md:hidden lg:inline mr-6 mb-6 md:mb-0">
              {Object.values(selectedVariantOptions).join(' / ')}
            </span>
            <div className="Builder__total__selected-color__icons flex mb-6 md:mb-0">
              {Object.entries(selectedVariantOptions).map(([key, value]) => {
                const group = selectedTrailer.variantGroups.find(
                  variantGroup => variantGroup.name === key
                );
                const variant = group?.variants.find(variant => variant.name === value);

                if (!group || !variant) return null;

                return (
                  <div
                    key={`Builder__total__selected-group--${group._key}`}
                    className="flex items-center"
                  >
                    <div className="Builder__total__selected-color__icon w-8 h-8 md:w-10 md:h-10 relative ml-6">
                      <Image
                        hideLoadingPlaceholder={true}
                        src={variant.icon}
                        alt={`${variant.name} color selector`}
                        layout="fill"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            {!submissionDisabled && (
              <div className="Builder__total__continue-button flex justify-center md:ml-12 w-full md:w-auto">
                <Button className="w-full md:w-auto" variant="primary" onClick={_continue}>
                  Continue
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {!submissionDisabled && (
        <div className="Builder__total__purchase-process flex flex-wrap md:grid md:grid-cols-2 md:gap-x-6 lg:grid lg:grid-cols-4 lg:gap-x-20">
          <div className="Builder__total__purchase-process__today mb-8">
            <div className="mb-2">
              <span className="font-grotesk-sub-headline-news uppercase text-fire">Today</span>
            </div>
            <div className="Builder__total__purchase-process-text">
              <span className="font-grotesk-news text-xs">
                Complete a fully refundable $500 reservation charge online by credit card.
              </span>
            </div>
          </div>
          <div className="Builder__total__purchase-process__soon mb-8">
            <div className="mb-2">
              <span className="font-grotesk-sub-headline-news uppercase text-fire">Soon</span>
            </div>
            <div className="Builder__total__purchase-process-text">
              <span className="font-grotesk-news text-xs">
                Sign the Escapod Purchase Agreement and pay a $6,500 non-refundable deposit.
              </span>
            </div>
          </div>
          <div className="Builder__total__purchase-process__later mb-8">
            <div className="mb-2">
              <span className="font-grotesk-sub-headline-news uppercase text-fire">Later</span>
            </div>
            <div className="Builder__total__purchase-process-text">
              <span className="font-grotesk-news text-xs">
                Finalize build details and pricing 90 days prior to estimated pickup date.
              </span>
            </div>
          </div>
          <div className="Builder__total__purchase-process__finally mb-8">
            <div className="mb-2">
              <span className="font-grotesk-sub-headline-news uppercase text-fire">Finally</span>
            </div>
            <div className="Builder__total__purchase-process-text">
              <span className="font-grotesk-news text-xs">
                Complete payment before pickup of your Escapod, or within 30 days of completion.
              </span>
            </div>
          </div>
        </div>
      )}

      {!!baseFeatureOverlay && (
        <div className="Builder__base-features__info-overlay fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-50">
          <div
            className="Builder__base-features__info-overlay__background absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-20 z-0"
            onClick={closeBaseFeatureOverlay}
          />
          <div className="Builder__base-features__info-overlay__inner-wrapper inline-block bg-white p-8 w-full md:w-5/6 lg:w-2/3 2xl:w-1/2 rounded-sm shadow-lg z-50">
            <div className="Builder__base-features__info-overlay__name mb-8 md:mb-10">
              <span className="text-fire font-grotesk-sub-headline-news text-[15px] md:text-[22px] tracking-wide">
                {baseFeatureOverlay.name}
              </span>
            </div>
            {!!baseFeatureOverlay.images && (
              <div className="Builder__base-features__info-overlay__images w-full flex flex-nowrap items-center justify-between mb-4">
                {baseFeatureOverlay.images?.map(image => (
                  <div
                    className="Builder__base-features__info-overlay__image-wrapper w-full"
                    key={image._key}
                  >
                    <div
                      className="Builder__base-features__info-overalay__image relative"
                      style={{
                        paddingBottom: `${
                          ((image.metadata?.dimensions.height || 0) /
                            (image.metadata?.dimensions.width || 0)) *
                          100
                        }%`
                      }}
                    >
                      <Image src={image.src} layout="fill" alt={image.alt} />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="Builder__base-features__info-overlay__description text-sm mb-8 leading-relaxed">
              <PortableText content={baseFeatureOverlay.description} />
            </div>
            <div className="Builder__info-overlay__add-remove-back-buttons flex items-center justify-start">
              <div className="Builder__info-overlay__back-button">
                <Button onClick={closeBaseFeatureOverlay} variant="primary">
                  <span className="text-xs font-grotesk-sub-headline-news">Close</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {infoOverlay && (
        <div className="Builder__info-overlay fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-50">
          <div
            className="Builder__info-overlay__background absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-20 -z-10"
            onClick={closeInfoOverlay}
          />
          <div className="Builder__info-overlay__inner-wrapper inline-block bg-white p-6 w-11/12 md:w-[61%] lg:w-1/3 rounded-sm shadow-lg">
            <div className="Builder__info-overlay__name-price flex items-center mb-4 w-full">
              <div className="Builder__info-overlay__name pr-3 md:pr-5 py-1">
                <span className="text-fire font-grotesk-sub-headline-news text-[15px] md:text-[22px] tracking-wide">
                  {infoOverlay.name}
                </span>
              </div>
              <div className="Builder__info-overlay__price">
                <span className="font-grotesk-sub-headline-news text-xs md:text-sm tracking-wide">
                  ${infoOverlay.price}
                </span>
              </div>
            </div>
            {!!infoOverlay.images &&
              !!infoOverlay.images.length &&
              infoOverlay.images?.map(image => (
                <div
                  className="Builder__info-overlay__image relative mb-4"
                  key={infoOverlay._key}
                  style={{
                    paddingBottom: `${
                      ((image.metadata?.dimensions.height || 0) /
                        (image.metadata?.dimensions.width || 0)) *
                      100
                    }%`
                  }}
                >
                  <Image
                    src={image.src}
                    layout="fill"
                    objectFit="cover"
                    alt={`${infoOverlay.name}`}
                  />
                </div>
              ))}
            {!!infoOverlay.description && (
              <div className="Builder__info-overlay__description mb-6 text-xs md:text-sm tracking-wide">
                <PortableText content={infoOverlay.description} />
              </div>
            )}
            {infoOverlayIntent === 'add' && !!mutate?.conflictsForModification(infoOverlay).length && (
              <div className="Builder__info-overlay__conflicts mb-6">
                <span className="text-xs text-fire">
                  These items conflict with this add-on and will be removed:{' '}
                </span>
                <span className="text-xs">
                  {mutate
                    ?.conflictsForModification(infoOverlay)
                    .map(conflict => conflict.name)
                    .join(', ')}
                </span>
              </div>
            )}
            {infoOverlayIntent === 'add' &&
              !!mutate?.unmetDependenciesForModification(infoOverlay).length && (
                <div className={cx('Builder__info-overlay__unmet-dependencies mb-6', {})}>
                  <span className="text-xs text-fire">
                    These items are required dependencies for this add-on and will also be added:{' '}
                  </span>
                  <span className="text-xs">
                    {mutate
                      ?.unmetDependenciesForModification(infoOverlay)
                      .map(dependency => dependency.name)
                      .join(', ')}
                  </span>
                </div>
              )}
            {infoOverlayIntent === 'remove' &&
              !!mutate?.selectedDependentsForModification(infoOverlay).length && (
                <div className="Builder__info-overlay__selected-dependencies mb-6">
                  <span className="text-xs text-fire">
                    These items require this add-on and will also be removed:{' '}
                  </span>
                  <span className="text-xs">
                    {mutate
                      ?.selectedDependentsForModification(infoOverlay)
                      .map(conflict => conflict.name)
                      .join(', ')}
                  </span>
                </div>
              )}
            <div className="Builder__info-overlay__add-remove-back-buttons flex items-center justify-end">
              <div className="Builder__info-overlay__back-button pr-4">
                <Button onClick={closeInfoOverlay} variant="no-style">
                  <span className="text-xs font-grotesk-sub-headline-news">Back</span>
                </Button>
              </div>
              {infoOverlayIntent === 'add' && (
                <div className="Builder__info-overlay__add-button">
                  <Button onClick={addFromInfoOverlay} variant="primary">
                    Add
                  </Button>
                </div>
              )}
              {infoOverlayIntent === 'remove' && (
                <div className="Builder__info-overlay__remove-button">
                  <Button onClick={removeFromInfoOverlay} variant="primary">
                    Remove
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </BlockWrapper>
  );
};

export default Builder;
