import React, { FC, useCallback, useState } from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import Datepicker from 'react-datepicker';
import Image from 'next/image';

import { Block } from 'types';
import { Button, TextField, BlockWrapper } from 'components';
import { useRentalBookingsContext, useRentalBookingsMutate } from 'contexts/RentalBookingsContext';

import loader from '../../public/images/loader.gif';
import iconClose from 'public/images/v2-icon-close.svg';

const formatDate = (date: Date) => format(date, 'EEEE, MMMM dd, yyyy');

export type TRentalBookings = Block<'rentalBookings', { title: string }>;
export const RentalBookings: FC<TRentalBookings> = ({ title }) => {
  const {
    pickupDate,
    dropoffDate,
    total,
    firstAvailablePickup,
    firstAvailableDropoff,
    isPickupAvailable,
    isDropoffAvailable,
    status
  } = useRentalBookingsContext();
  const mutate = useRentalBookingsMutate();

  const [bookingIsPending, setBookingIsPending] = useState<boolean>(false);
  const [overlayIsActive, setOverlayIsActive] = useState<boolean>(false);
  const openOverlay = useCallback(() => setOverlayIsActive(true), []);
  const closeOverlay = useCallback(() => setOverlayIsActive(false), []);

  // TO-DO: Error handling
  const [errors, setErrors] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }>({ firstName: '', lastName: '', email: '', phone: '' });
  const [customer, setCustomer] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }>({ firstName: '', lastName: '', email: '', phone: '' });
  const setFirstName = useCallback(
    (value: string | number) => setCustomer({ ...customer, firstName: value as string }),
    [customer]
  );
  const setLastName = useCallback(
    (value: string | number) => setCustomer({ ...customer, lastName: value as string }),
    [customer]
  );
  const setEmail = useCallback(
    (value: string | number) => setCustomer({ ...customer, email: value as string }),
    [customer]
  );
  const setPhone = useCallback(
    (value: string | number) => setCustomer({ ...customer, phone: value as string }),
    [customer]
  );

  const submit = useCallback(() => {
    setBookingIsPending(true);

    if (mutate) mutate?.startBooking(customer);

    setTimeout(() => {
      setBookingIsPending(false);
    }, 5000);
  }, [customer, mutate]);

  return (
    <BlockWrapper className="RentalBookings">
      <h2 className="mb-12 w-full text-center font-grotesk-headline-news text-2xl md:text-3xl lg:mb-16 lg:text-left">
        {title}
      </h2>
      <div className="RentalBookings__ui flex flex-col lg:grid lg:grid-cols-4 lg:gap-16">
        <div className="RentalBookings__location mb-16 flex flex-col lg:mb-0">
          <span className="mb-2 font-grotesk-sub-headline text-xl text-fire lg:mb-4 lg:text-[22px]">
            Location
          </span>
          <Button variant="primary" onClick={() => {}}>
            Coalville, UT
          </Button>
        </div>
        <div className="RentalBookings__pickup mb-8 flex flex-col items-start lg:mb-0">
          <span className="mb-2 font-grotesk-sub-headline text-xl text-fire lg:mb-4 lg:text-[22px]">
            Pickup
          </span>
          <Datepicker
            className="w-full"
            selected={pickupDate}
            onChange={date => mutate?.setPickupDate(date)}
            filterDate={date => isPickupAvailable(date, dropoffDate)}
            minDate={firstAvailablePickup}
            maxDate={dropoffDate || null}
            customInput={
              <Button variant="neutral">
                {!!pickupDate ? formatDate(pickupDate) : 'Select Pickup Date'}
              </Button>
            }
          />
          <Button
            className={cx('mt-3 !font-grotesk-news text-xs text-fire underline', {
              'pointer-events-none opacity-0': !pickupDate
            })}
            variant="no-style"
            onClick={mutate?.clearPickupDate}
          >
            Clear Pickup Date
          </Button>
        </div>
        <div className="RentalBookings__dropoff mb-8 flex flex-col items-start lg:mb-0">
          <span className="mb-2 font-grotesk-sub-headline text-xl text-fire lg:mb-4 lg:text-[22px]">
            Dropoff
          </span>
          <Datepicker
            className="w-full"
            selected={dropoffDate}
            onChange={date => mutate?.setDropoffDate(date)}
            filterDate={date => isDropoffAvailable(date, pickupDate)}
            minDate={pickupDate || firstAvailableDropoff}
            customInput={
              <Button variant="neutral">
                {!!dropoffDate ? formatDate(dropoffDate) : 'Select Dropoff Date'}
              </Button>
            }
          />
          <Button
            className={cx('mt-3 !font-grotesk-news text-xs text-fire underline', {
              'pointer-events-none opacity-0': !dropoffDate
            })}
            variant="no-style"
            onClick={mutate?.clearDropoffDate}
          >
            Clear Dropoff Date
          </Button>
        </div>
        <div className="RentalBookings__location mb-8 flex flex-col lg:mb-0">
          <span className="mb-2 font-grotesk-sub-headline text-xl lg:mb-4 lg:text-[22px]">
            Total &mdash; ${total}
          </span>
          <Button variant="primary" disabled={!pickupDate || !dropoffDate} onClick={openOverlay}>
            Continue
          </Button>
        </div>
      </div>
      {overlayIsActive && (
        <div className="RentalBookings__overlay fixed top-0 left-0 right-0 bottom-0 z-50 bg-stone-900 bg-opacity-50 p-6 md:p-8">
          <div className="RentalBookings__overlay-container relative h-full w-full overflow-auto bg-stone-200 p-8 md:p-16 lg:p-24">
            <div className="RentalBookings__overlay-container__inner flex flex-col flex-wrap items-start justify-start py-6 md:py-0 lg:flex-row">
              <div className="RentalBookings__overlay-inner__body mb-16 w-full flex-col md:pr-16 lg:mb-0 lg:w-1/2 lg:pr-24">
                <span className="mb-8 block font-grotesk-headline text-2xl lg:text-3xl">
                  Confirm Rental
                </span>
                <span className="leading-relaxed">
                  Please enter your contact information to continue to the following screen where
                  you will be asked to provide your driver&apos;s license and payment method.
                </span>
              </div>
              <div className="RentalBookings__form w-full lg:w-1/2">
                <form>
                  <TextField
                    label="First Name"
                    ariaLabel="First Name"
                    name="firstName"
                    variant="primary"
                    value={customer.firstName}
                    onChange={setFirstName}
                    className="mb-8"
                  />
                  <TextField
                    label="Last Name"
                    ariaLabel="Last Name"
                    name="lastName"
                    variant="primary"
                    value={customer.lastName}
                    onChange={setLastName}
                    className="mb-8"
                  />
                  <TextField
                    label="Email Address"
                    ariaLabel="Email Address"
                    name="email"
                    variant="primary"
                    value={customer.email}
                    onChange={setEmail}
                    className="mb-8"
                  />
                  <TextField
                    label="Phone Number"
                    ariaLabel="Phone Number"
                    name="phone"
                    variant="primary"
                    value={customer.phone}
                    onChange={setPhone}
                    className="mb-16"
                  />
                  <div className="flex justify-end items-center">
                    <div
                      className={cx('RentalBookings__loader mr-6', {
                        hidden: !bookingIsPending || status === 'FULFILLED'
                      })}
                    >
                      <div className="h-5 w-5 relative">
                        <Image src={loader} layout="fill" alt="Submit button loading GIF" />
                      </div>
                    </div>
                    <Button
                      disabled={status === 'FULFILLED' || bookingIsPending}
                      variant="primary"
                      onClick={submit}
                    >
                      {status === 'FULFILLED'
                        ? 'Success!'
                        : bookingIsPending
                        ? 'Submitting'
                        : 'Continue'}
                    </Button>
                  </div>
                  {status === 'FULFILLED' && (
                    <div className="mt-6 p-4 bg-green-50 border-1 border-green-400">
                      <span className="text-sm">
                        Your booking request has been submitted! Our Rentals Team will follow up
                        with next steps. Reach out to{' '}
                        <a
                          href="mailto:rentals@escapod.us"
                          target="_blank"
                          rel="noreferrer"
                          className="underline"
                        >
                          rentals@escapod.us
                        </a>{' '}
                        if you have any questions.
                      </span>
                    </div>
                  )}
                </form>
              </div>
            </div>

            <button onClick={closeOverlay} className="absolute top-0 right-0 mr-8 mt-8">
              <Image src={iconClose} alt="Close" />
            </button>
          </div>
        </div>
      )}
    </BlockWrapper>
  );
};

export default RentalBookings;
